import React from 'react'
import { Input } from 'components'
import { Form } from 'react-final-form'
import { Button } from 'components/Button'
import { Dropzone } from 'components/Dropzone'
import { Modal } from 'components/Modal'
import { Select } from 'components/Select'
import { useMutation, useQuery } from '@tanstack/react-query'
import { DESTROY_DOCUMENT_TYPES, GET_DOCUMENT_TYPES, UPLOAD_DOCUMENT } from 'graphql'
import { toast } from 'react-toastify'
import { useState } from 'react'
import { EditDocumentTypesModal } from './EditDocumentTypesModal'
import { Radio } from 'components/Radio'
import { Checkbox } from 'components/Checkbox'

const UploadContractorDocumentModal = ({
  setIsUploadModalOpen,
  isUploadModalOpen,
  documentsFilters,
  refetchContractorData,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const { mutate, isLoading } = useMutation(UPLOAD_DOCUMENT, {
    onSuccess: res => {
      if (res) {
        refetchContractorData()
        setIsUploadModalOpen(false)
        toast('User uploaded document', { type: 'success' })
      }
    },
    onError: () => {
      toast('Unexpected error occurred, try again', { type: 'error' })
    },
  })

  const { mutate: removeDocumentsTypes } = useMutation(DESTROY_DOCUMENT_TYPES, {
    onSuccess: res => {
      if (res) {
        toast('User removed document types', { type: 'success' })
      }
    },
    onError: () => {
      toast('Unexpected error occurred, try again', { type: 'error' })
    },
  })

  const {
    data: documentTypes,
    refetch: getDocumentTypesAgain,
    isLoading: isLoadingDocumentTypes,
  } = useQuery([
    {
      query: GET_DOCUMENT_TYPES,
    },
  ])

  const handleRemoveDocumentType = async values => {
    const docsToRemove = Object.keys(values?.docsToDelete).filter(key => values?.docsToDelete[key])
    await removeDocumentsTypes({ input: { id: docsToRemove } })
    setIsDeleteModalOpen(false)
    setTimeout(() => {
      getDocumentTypesAgain()
    }, 300)
  }

  const arrayOfAvailableTypes = !isLoadingDocumentTypes
    ? [
        ...documentTypes?.data?.data?.documentTypeCategories?.[0]?.documentType,
        ...documentTypes?.data?.data?.documentTypeCategories?.[1]?.documentType,
      ]?.map(el => el.name)
    : []

  return (
    <Modal className={'modal-upload-document'} isOpen={isUploadModalOpen} onClose={() => setIsUploadModalOpen(false)}>
      <div className="modal-content modal-content-upload-document">
        <p className="modal-content-upload-document__title">Upload document</p>
        <Form
          onSubmit={v => {
            mutate({
              name: v.name,
              documentTypeId: v.documentTypeId,
              clientId: v.clientId,
              file: v.document[0],
              motorCarrier: v.motorCarrier,
              expiration: v?.doesNotExpire ? '01/01/9999' : v.expirationDate,
              usdot: v.usdot,
              newDocument: v.newDocument,
              documentTypeCategoryId: v.documentTypeCategoryId,
            })
          }}
          render={({ handleSubmit, values, form: { change } }) => (
            <form onSubmit={handleSubmit} noValidate>
              <div className="modal-content-upload-document__container">
                <div className="modal-content-upload-document__container-left">
                  <Dropzone required name="document" />
                  <Button disabled={isLoading} className="modal-content-upload-document__button-save">
                    SAVE
                  </Button>
                </div>
                <div className="modal-content-upload-document__container-right">
                  <div className="modal-content-upload-document__inputs-left">
                    <Input name="name" label="Document Name" type="text" required />
                    <Select
                      options={documentTypes?.data?.data?.documentTypeCategories}
                      name="documentTypeId"
                      label="Select Document Type"
                      isDocumentTypeIdSelect
                      required={!values.newDocument}
                    />
                    <Input
                      classNameModifier="new-document-input"
                      name="newDocument"
                      label="Create New Document Type"
                      type="text"
                      customValidation={v => {
                        if (arrayOfAvailableTypes?.includes(v)) {
                          return 'Document type is not permitted to match the name of an existing document type'
                        }
                      }}
                    />
                    <label className="modal-content-upload-document__label">Select Document Category</label>
                    <Radio name="documentTypeCategoryId" label="Carrier Level Document" value={'1'} />
                    <Radio name="documentTypeCategoryId" label="Owner/Worker Level Document" value={'2'} />
                    <button
                      type="button"
                      className="modal-content-upload-document__edit-button"
                      onClick={() => {
                        setIsDeleteModalOpen(true)
                      }}
                    >
                      Edit Document Types
                    </button>
                  </div>
                  <div className="modal-content-upload-document__inputs-right">
                    <Select options={documentsFilters?.clientId} name="clientId" label="Client ID" />
                    <Input
                      classNameModifier="expiration-date-document-input"
                      required={!values?.doesNotExpire}
                      name="expirationDate"
                      label="Expiration Date"
                      type="date"
                      disabled={values?.doesNotExpire}
                    />
                    <Checkbox
                      name="doesNotExpire"
                      onChange={e => {
                        e.target.checked ? change('expirationDate', '9999-01-01') : change('expirationDate', null)
                      }}
                      label="Does not expire"
                    />
                  </div>
                </div>
              </div>
              <EditDocumentTypesModal
                isDeleteModalOpen={isDeleteModalOpen}
                setIsDeleteModalOpen={setIsDeleteModalOpen}
                handleRemoveDocumentType={handleRemoveDocumentType}
                values={values}
                documentTypeCategories={documentTypes?.data?.data?.documentTypeCategories}
              ></EditDocumentTypesModal>
            </form>
          )}
        />
      </div>
    </Modal>
  )
}

export default UploadContractorDocumentModal
