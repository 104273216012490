import React, { useRef, useState, useEffect, useMemo } from 'react'
import { ArrowDropdownIcon, ArrowDropupIcon } from 'assets/icons'
import { Field } from 'react-final-form'
import './Select.scss'
import clsx from 'clsx'
import { metaToClassName } from 'utility/helpers'

const Select = ({ name, className, options, label, required, withoutLabel, isDocumentTypeIdSelect, ...props }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const optionsRef = useRef()

  const clickOutside = event => {
    if (optionsRef.current && !optionsRef.current.contains(event.target)) {
      setIsDropdownOpen(false)
    }
  }
  useEffect(() => {
    document?.addEventListener('click', clickOutside)
    return () => {
      document?.removeEventListener('click', clickOutside)
    }
  }, [])

  const validateFunction = value => {
    if (!value && required) {
      return 'This is a Required Field'
    }
  }

  let savedNamesOfDocumentTypesOptions = []
  if (isDocumentTypeIdSelect) {
    options?.forEach(option => {
      savedNamesOfDocumentTypesOptions.push(option.documentType)
    })
    savedNamesOfDocumentTypesOptions = savedNamesOfDocumentTypesOptions.flatMap(el => el)
  }

  return (
    <Field allowNull={true} name={name} validate={validateFunction}>
      {({ input, meta }) => (
        <div
          ref={optionsRef}
          className={clsx(metaToClassName('select', meta), className)}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <label className="select__label" htmlFor={name}>
            {label}
            {required && <span className="select__required">{!withoutLabel ? '*' : ''}</span>}
          </label>
          <div className="select__input" {...input} {...props} type={'text'} placeholder={''}>
            {input.value === null
              ? 'None'
              : isDocumentTypeIdSelect
              ? savedNamesOfDocumentTypesOptions.filter(el => el.id === input.value)?.[0]?.name
              : input.value}
          </div>
          {isDropdownOpen ? (
            <ArrowDropupIcon className="select__arrow" />
          ) : (
            <ArrowDropdownIcon className="select__arrow" />
          )}
          {isDropdownOpen && !isDocumentTypeIdSelect && (
            <>
              <div className="select__options">
                {(!options || options?.length === 0) && <div className="select__option">No options</div>}
                {options && options?.length !== 0 && (
                  <div
                    className={clsx('select__option', input.value === null && 'select__option--selected')}
                    onClick={() => input.onChange(null)}
                  >
                    None
                  </div>
                )}
                {options?.map((el, i) => {
                  return (
                    <div
                      className={clsx('select__option', el === input.value && 'select__option--selected')}
                      onClick={() => input.onChange(el === 'None' || input.value === el ? null : el)}
                      key={i}
                    >
                      {el}
                    </div>
                  )
                })}
              </div>
            </>
          )}
          {isDropdownOpen && isDocumentTypeIdSelect && (
            <>
              <div className="select__options select__options--with-categories">
                {options.map(category => {
                  return (
                    <React.Fragment key={category?.id}>
                      <div disabled className={'select__option select__option--category'}>
                        {category?.name}
                      </div>
                      {category?.documentType?.map(el => {
                        return (
                          <div
                            key={el?.id}
                            className={'select__option select__option--category-item'}
                            onClick={() => input.onChange(el?.id)}
                          >
                            {el?.name}
                          </div>
                        )
                      })}
                    </React.Fragment>
                  )
                })}
              </div>
            </>
          )}
          {meta.error && meta.touched && <span className="select__error">*{meta.error}</span>}
        </div>
      )}
    </Field>
  )
}

export default Select
