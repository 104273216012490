import { toast } from 'react-toastify'
import { api } from 'utility/api'

const checkGraphQlResponse = res => {
  const queryKey = res?.data?.data ? Object.keys(res?.data?.data)[0] : null
  if ((queryKey && res?.data?.data[queryKey]?.errors?.length > 0) || res?.data?.errors?.length > 0) {
    const errorsArray =
      queryKey && !res?.data?.errors  ? Object.values(res?.data?.data[queryKey]?.errors) : res?.data?.errors
    if (errorsArray[0]?.message) {
      errorsArray.forEach(el => {
        toast(el?.message, { type: 'error' })
      })
    } else {
      errorsArray.forEach(el => {
        toast(el, { type: 'error' })
      })
    }

    return Promise.reject(res)
  } else {
    return res
  }
}

export const graphql = async (query = '', variables = {}, axiosOptions = {}) => {
  const { auth_token } = JSON.parse(localStorage.getItem('_centro_auth'))
  return await api
    .post(
      '/graphql',
      { query, variables },
      { headers: { Authorization: auth_token, ...axiosOptions.headers }, ...axiosOptions }
    )
    .then(res => {
      return checkGraphQlResponse(res)
    })
    .catch(res => {
      return Promise.reject(res)
    })
}

export const graphqlForUpload = async data => {
  const { auth_token } = JSON.parse(localStorage.getItem('_centro_auth'))
  return await api
    .post('/graphql', data, {
      headers: { Authorization: auth_token, 'Content-Type': 'multipart/form-data' },
    })
    .then(res => {
      return checkGraphQlResponse(res)
    })
    .catch(res => {
      return Promise.reject(res)
    })
}
