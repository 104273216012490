import { FindUserIcon } from 'assets/icons'
import { ContractorDocumentsStatus } from 'components/ContractorDocumentsStatus'
import { Link } from 'react-router-dom'

export const columns = [
  {
    accessorFn: row => row?.companyName,
    header: 'Company Name',
    id: 'company_name',
  },
  {
    accessorFn: row => row?.markets,
    header: 'Market Type',
    id: 'market_type',
    cell: cell => <span>{cell.getValue()[0]?.name}</span>,
  },
  {
    accessorFn: row => row?.documents,
    header: 'Documentation',
    id: 'documents',
    cell: cell => (
      <span>
        {cell.getValue()}
        {cell.getValue() !== 1 ? ' Documents' : ' Document'}
      </span>
    ),
  },
  {
    accessorFn: row => row.status,
    header: 'Status',
    id: 'status',
    cell: cell =>
      cell.getValue()?.value > 0 ? (
        <ContractorDocumentsStatus type={cell.getValue()?.type} value={cell.getValue()?.value} />
      ) : (
        '---'
      ),
  },
  {
    accessorFn: row => row?.id,
    header: 'Client ID',
    id: 'id',
  },
  {
    accessorFn: row => row?.contactName,
    header: 'Contact Name',
    id: 'contact_name',
  },
  {
    accessorFn: row => row?.contactMobilePhone,
    header: 'Contact Phone',
    id: 'contact_mobile_phone',
  },
  {
    accessorFn: row => row?.motorCarrier,
    header: 'Motor Carrier',
    id: 'motor_carrier',
  },
  {
    accessorFn: row => row?.usdot,
    header: 'USDOT',
    id: 'usdot',
  },
  {
    accessorFn: row => row?.createdAt,
    header: 'Account Created',
    id: 'created_at',
  },

  {
    accessorKey: 'id',
    id: 'url',
    header: '',
    cell: cell => (
      <Link to={'/contractors/' + cell.getValue()}>
        <div className="column-icon-container">
          <div className="column-icon">
            <FindUserIcon />
            <span>View</span>
          </div>
        </div>
      </Link>
    ),
  },
]
