import React from 'react'
import { Field } from 'react-final-form'
import './Radio.scss'

const Radio = ({ name, label, value }) => {
  return (
    <Field name={name} type="radio" value={value}>
      {({ input }) => (
        <div className="radio">
          <span className="radio__checkmark"></span>
          <input
            id={name + '_' + value}
            className="radio__input"
            {...input}
            checked={input.checked}
            value={value}
            onChange={input.onChange}
            type={'radio'}
          />
          <label htmlFor={name + '_' + value} className="radio__label">
            {label}
          </label>
          {<span className="radio__checked"></span>}
        </div>
      )}
    </Field>
  )
}

export default Radio
