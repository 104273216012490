import React from 'react'
import { PieChart, Pie, Cell, ResponsiveContainer, Label } from 'recharts'
import './DashboardPieChart.scss'

const DashboardPieChart = ({ colors, data, count, description }) => {
  return (
    <ResponsiveContainer width={'90%'} aspect={1}>
      <PieChart>
        <Pie stroke="none" data={data} innerRadius={'68%'} outerRadius={'95%'} dataKey="value">
          {data.map((_, index) => (
            <Cell key={`cell-${index}`} fill={colors[index]} />
          ))}
          <Label value={count} position="centerBottom" className="dashboard-pie-chart__label-top" />
          <Label value={description} position="centerTop" className="dashboard-pie-chart__label-bottom" />
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  )
}

export default DashboardPieChart
