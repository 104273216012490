import { ErrorIcon } from 'assets/icons'
import clsx from 'clsx'
import { useProgressiveBackground } from 'utility/hooks'
import './Verification.scss'

const Verification = ({ children, resend, onResend, isResetPassword, help, qrKey, padding, className }) => {
  const { style } = useProgressiveBackground()
  return (
    <div className={clsx('verification', className)} style={style}>
      <div className="verification__container">
        {isResetPassword && (
          <div className="verification__top-message">
            <ErrorIcon />
            <p>We need to verify your account before we can recover your password.</p>
          </div>
        )}
        <div className="verification__content" style={style}>
          {children}
          <div className={`verification__links${padding ? ' padding' : ''}`}>
            {resend && (
              <p className="help help__resend" onClick={onResend}>
                Didn't get a code? Resend verification code.
              </p>
            )}
            {help && (
              <p className="help">
                Need help? Contact <a href="mailto:admin@centronow.com">admin@centronow.com</a>
              </p>
            )}
            {qrKey && (
              <p className="help">
                Having trouble scanning? Enter this key in the app:
                <br />
                {qrKey}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Verification
