import { useMutation } from '@tanstack/react-query'
import { FiltersIcon } from 'assets/icons'
import { FilterBadge } from 'components/FilterBadge'
import Filters from 'components/Filters/Filters'
import { Pagination } from 'components/Pagination'
import { SearchInput } from 'components/SearchInput'
import { Spinner } from 'components/Spinner'
import { Table } from 'components/Table'
import { DESTROY_DOCUMENT } from 'graphql'
import { renderColumns } from 'pages/Contractor/columns'
import { PagesInfo } from 'pages/PagesInfo'
import React, { useEffect } from 'react'
import { toast } from 'react-toastify'

const ContractorDocuments = ({
  isFiltersOpen,
  setIsFiltersOpen,
  values,
  change,
  reset,
  setFilters,
  filters,
  filtersLength,
  contractorFilterData,
  contractorData,
  contractorDataIsLoading,
  refetchContractorData,
  onSort,
}) => {
  useEffect(() => {
    setFilters(values)
  }, [values])

  const { mutate: destroyDoc } = useMutation(DESTROY_DOCUMENT, {
    onSuccess: () => {
      toast('Document removed successfully', { type: 'success' })
      refetchContractorData();
    },
  })

  return (
    <>
      <div className="contractor-filters">
        <FiltersIcon onClick={() => setIsFiltersOpen(true)} className="contractor-filters__icon" />
        <Filters
          documentsFiltersData={contractorFilterData?.data?.data?.contractorFilters}
          isOpen={isFiltersOpen}
          onClose={() => setIsFiltersOpen(false)}
          type={"contractorDocuments"}
          filtersArray={['createdAt', 'documentName', 'documentType', 'documentStatus']}
        />
        <span className="contractor-filters__text">Filter</span>
        {values &&
          Object.keys(values).map((el, i) => {
            return <FilterBadge onDelete={() => change(el, undefined)} key={el + i} fieldName={el} value={values[el]} />
          })}

        {filtersLength > 0 && (
          <button
            onClick={() => {
              setFilters({ limit: 9 })
              reset()
            }}
            type="button"
            className="contractor-filters__delete-button"
          >
            Clear All filters
          </button>
        )}
      </div>
      <div className="contractor-documents glass">
        <div className="contractor-table-header">
          <h4 className="contractor-table-header__title">Documents List</h4>
          <SearchInput name="text" />
        </div>
        {contractorDataIsLoading ? (
          <div className="contractor__spinner">
            <Spinner centered />
          </div>
        ) : (
          <>
            {contractorData?.documents?.collection.length === 0 ? (
              <div className="contractor__no-docs-wrapper">
                <h3>This contractor does not have any documents, or no documents are associated with these filters.</h3>
              </div>
            ) : (
              <>
                <Table
                  data={contractorData?.documents?.collection || []}
                  columns={renderColumns(destroyDoc)}
                  sortable
                  onSort={onSort}
                  orderBy={filters?.orderBy}
                />{' '}
                <div className="contractor__pagination">
                  <PagesInfo
                    currentPage={contractorData?.documents?.metadata?.currentPage}
                    recordsPerPage={contractorData?.documents?.metadata?.limitValue}
                    totalRecords={contractorData?.documents?.metadata?.totalCount}
                    type="documents"
                  />
                  <Pagination
                    change={change}
                    totalRecords={contractorData?.documents?.metadata?.totalCount}
                    totalPages={contractorData?.documents?.metadata?.totalPages}
                    currentPage={contractorData?.documents?.metadata?.currentPage}
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default ContractorDocuments
