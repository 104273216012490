import { SearchIcon } from 'assets/icons'
import React from 'react'
import { Field } from 'react-final-form'

import './SearchInput.scss'

const SearchInput = ({ name, ...props }) => {
  return (
    <Field name={name}>
      {({ input }) => (
        <div className="search-input">
          <SearchIcon />
          <input className="search-input__field" {...input} {...props} type={'text'} placeholder={'Search'} />
        </div>
      )}
    </Field>
  )
}

export default SearchInput
