export const metaToClassName = (className, meta) =>
  Object.keys(meta)
    .reduce(
      (acc, key) => {
        if (meta[key] && !['string', 'object', 'array'].includes(typeof meta[key])) acc.push(className + '--' + key)
        return acc
      },
      [className]
    )
    .join(' ')

export const setRootFontSize = () => {
  const html = document.querySelector('html')
  if (html.offsetWidth > 1366) {
    html.style.fontSize = (html.offsetWidth * 10) / 1366 + 'px'
  }
  window.addEventListener('resize', () => {
    if (html.offsetWidth > 1366) {
      html.style.fontSize = (html.offsetWidth * 10) / 1366 + 'px'
    }
  })
}

export const snakeCaseToTitleCase = value => {
  return value
    .replace(/(^\w)/g, g => g[0].toUpperCase())
    .replace(/([-_]\w)/g, g => ' ' + g[1].toUpperCase())
    .trim()
}

export const getLastDayOfWeek = () => {
  const curr = new Date() // get current date
  const first = curr.getDate() - curr.getDay()
  const last = first + 6

  return new Date(curr.setDate(last)).toLocaleDateString()
}

export const orderDataByArrayOfKeys = (data, arrayOfKeys) =>
  data?.map?.(item => arrayOfKeys?.reduce?.((acc, key) => ({ ...acc, [key]: item[key] }), {}) || {}) || []
