import React from 'react'
import { XlsFileIcon, DocShowIcon } from 'assets/icons'
import { DashboardPieChart } from 'components/DashBoardPieChart'
import { DashboardPieLegend } from 'components/DashboardPieLegend'

const HomeInformation = ({ values: { documentationStatus, contractorCompliance, latestSettlement } }) => {
  const firstChartColors = ['#a2282d', '#b9541f', '#b9901f', '#17a577', '#5c7cfa']
  const secondChartColors = ['#0c1233', '#5c7cfa']

  const firstChartData = [
    { name: 'Expired', value: documentationStatus?.expired },
    { name: 'Expiring (30 days)', value: documentationStatus?.expiring30Days },
    { name: 'Expiring (60 days)', value: documentationStatus?.expiring60Days },
    { name: 'Expiring (90 days)', value: documentationStatus?.expiring90Days },
    { name: 'Current', value: documentationStatus?.current },
  ]

  const secondChartData = [
    { name: 'Current', value: contractorCompliance?.current },
    { name: 'Document Needed', value: contractorCompliance?.documentationNeeded },
  ]

  return (
    <div className="home-information">
      <div className="glass home-information__item">
        <div className="home-information__left-container">
          <h4 className="home-information__title">Documentation Status</h4>{' '}
          <DashboardPieLegend data={firstChartData} colors={firstChartColors} />
        </div>
        <div className="home-information__right-container">
          <DashboardPieChart
            data={firstChartData}
            colors={firstChartColors}
            description="Total Documents"
            count={documentationStatus?.total}
          />
        </div>
      </div>
      <div className="glass home-information__item">
        <div className="home-information__left-container">
          <h4 className="home-information__title">Contractor Compliance</h4>
          <DashboardPieLegend data={secondChartData} colors={secondChartColors} />
        </div>
        <div className="home-information__right-container">
          <DashboardPieChart
            data={secondChartData}
            colors={secondChartColors}
            description="Total Contractors"
            count={contractorCompliance?.total}
          />
        </div>
      </div>
      <div className="glass home-information__item">
        <div className="home-information__left-container">
          <h4 className="home-information__title">Latest Settlement Upload</h4>
          <p className="home-information__file-name">{latestSettlement?.title}</p>
          <p className="home-information__upload-info">Upload On: {latestSettlement?.createdAt}</p>
        </div>
        <div className="home-information__right-container">
          <XlsFileIcon />
          <p className="home-information__path-name">{latestSettlement?.title}.xlsx</p>
          <div className="home-information__view-doc" onClick={() => window.open(latestSettlement?.url, '_blank')}>
            <DocShowIcon />
            <p>Download</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeInformation
