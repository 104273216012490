import React, { useEffect, useState } from 'react'
import { Header, Input } from 'components'
import { Form } from 'react-final-form'
import { Button } from 'components/Button'
import { Select } from 'components/Select'
import './AddEditContractor.scss'
import { useMutation, useQuery } from '@tanstack/react-query'
import { CONTRACTOR_QUERY, CREATE_CONTRACTOR, DESTROY_MARKET, DOCUMENTS_FILTER_QUERY, UPDATE_CONTRACTOR } from 'graphql'
import { USStatesAbbreviations } from 'utility/consts'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import { DropLeftCircle } from 'assets/icons'
import { Modal } from 'components/Modal'
import { Checkbox } from 'components/Checkbox'
import { CentroHorizontalLogo } from 'assets/logos'

const AddEditContractor = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [initialValues, setInitialValues] = useState({})
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const { refetch: getContractor } = useQuery([{ query: CONTRACTOR_QUERY, variables: { id } }], {
    enabled: false,
    onSuccess: res => {
      const contractor = res?.data?.data?.contractor
      setInitialValues({
        ...contractor,
        markets: contractor?.markets[0]?.name,
        businessPhone: contractor?.businessPhone?.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'),
        contactMobilePhone: contractor?.contactMobilePhone?.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'),
      })
    },
  })

  const { data: documentsFiltersData } = useQuery([
    {
      query: DOCUMENTS_FILTER_QUERY,
    },
  ])

  const { mutate: destroyMarket } = useMutation(DESTROY_MARKET, {
    onSuccess: () => {
      navigate('/contractors')
      toast('Markets removed successfully', { type: 'success' })
    },
  })

  const { mutate: createContractor } = useMutation(CREATE_CONTRACTOR, {
    onSuccess: data => {
      toast('Contractor created successfully', { type: 'success' })
      navigate('/contractors')
    },
    onError: () => {
      toast('Unexpected error occurred, try again', { type: 'error' })
    },
  })

  const { mutate: updateContractor } = useMutation(UPDATE_CONTRACTOR, {
    onSuccess: data => {
      toast('Contractor updated successfully', { type: 'success' })
      navigate('/contractors')
    },
    onError: () => {
      toast('Unexpected error occurred, try again', { type: 'error' })
    },
  })

  useEffect(() => {
    if (id) {
      getContractor()
    }
  }, [id])

  const handleSubmit = values => {
    const contractorInput = {
      ...values,
      markets: [values.markets],
      businessPhone: values?.businessPhone?.replaceAll('-', ''),
      contactMobilePhone: values?.contactMobilePhone?.replaceAll('-', ''),
    }
    if (id) {
      updateContractor({
        input: {
          contractorInput: { ...contractorInput, id },
        },
      })
    } else {
      createContractor({
        input: {
          contractorInput: contractorInput,
        },
      })
    }
  }

  const handleRemoveContractors = values => {
    if (Object.keys(values?.marketsToDelete).length === 0) {
      return
    }
    const keys = Object.keys(values?.marketsToDelete)
    const marketsToDelete = keys.filter(key => values?.marketsToDelete[key])
    destroyMarket({ input: { destroyMarketInput: { id: marketsToDelete } } })
  }

  return (
    <div className="add-edit-contractor">
      <div className="add-edit-contractor__header">
        <div>
          <Header />
          <button onClick={() => navigate(-1)} className="add-edit-contractor__back_btn">
            <DropLeftCircle /> <span>Back</span>
          </button>
        </div>
        <CentroHorizontalLogo className="add-edit-contractor__logo" />
      </div>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div className="glass add-edit-contractor__content">
              <h2 className="add-edit-contractor__title"> {id ? 'Edit' : 'Create'} Contractor Account</h2>
              <div className="add-edit-contractor-row">
                <div className="add-edit-contractor__input-box">
                  <Input name="companyName" label="Company Name" type="text" required />
                </div>
                <div className="add-edit-contractor__input-box">
                  <Input name="businessPhone" label="Business Phone" type="tel" maxLenght={12} required />
                </div>
                <div className="add-edit-contractor__input-box">
                  <Input name="businessExt" label="Business Phone Ext" type="number" maxLenght={4} />
                </div>
                <div className="add-edit-contractor__input-box add-edit-contractor__input-box--market">
                  <Select
                    options={documentsFiltersData?.data?.data?.documentFilters?.market?.map(el => el?.name)}
                    name="markets"
                    label="Market"
                  />
                </div>
              </div>
              <div className="add-edit-contractor-row add-edit-contractor-row--second">
                <div className="add-edit-contractor__input-box">
                  <Input name="businessAddress" label="Business Address" type="text" required />
                </div>
                <div className="add-edit-contractor__input-box">
                  <Input name="businessCity" label="Business City" type="text" required />
                </div>
                <div className="add-edit-contractor__input-box">
                  <Select
                    className="add-edit-contractor__state-select"
                    name="businessState"
                    label="Business State"
                    options={USStatesAbbreviations}
                    required
                  />
                  <Input name="businessZip" label="Business Zip" type="text" required />
                </div>
                <div className="add-edit-contractor__markets-editing-container">
                  <div className="add-edit-contractor__input-box add-edit-contractor__input-box--market">
                    <Input name="newMarket" label="Add new market" type="text" />
                  </div>
                  <button
                    type="button"
                    className="add-edit-contractor__edit-button"
                    onClick={() => {
                      setIsDeleteModalOpen(true)
                    }}
                  >
                    Edit Markets
                  </button>
                </div>
              </div>
              <div className="add-edit-contractor-row">
                <div className="add-edit-contractor__input-box">
                  <Input name="contactName" label="Contact First Name" type="text" required />
                </div>
                <div className="add-edit-contractor__input-box">
                  <Input name="contactLastName" label="Contact Last Name" type="text" required />
                </div>
                <div className="add-edit-contractor__input-box">
                  <Input name="contactMobilePhone" label="Contact Mobile" type="tel" maxLenght={12} required />
                </div>
              </div>
              <div className="add-edit-contractor-row">
                <div className="add-edit-contractor__input-box">
                  <Input name="contactEmail" label="Contact Email" type="text" required />
                </div>
              </div>
              <div className="add-edit-contractor__button-box">
                <Button>{id ? 'Edit' : 'Create'} Contractor Account</Button>
              </div>
            </div>
            <div className="centered-copyright">
              <p>© Centro Group LLC, 2023. All Rights Reserved.</p>{' '}
            </div>
            <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
              <div className="modal-content modal-content-edit-markets">
                <h2 className="modal-content-edit-markets__name">Delete Markets Created</h2>
                <div className="modal-content-edit-markets__checkboxes">
                  {documentsFiltersData?.data?.data?.documentFilters?.market?.map((el, i) => (
                    <Checkbox key={i} name={`marketsToDelete[${el?.name}]`} label={el?.name} />
                  ))}
                </div>
                <div className="modal-content__buttons">
                  <Button className="modal-content__button" onClick={() => handleRemoveContractors(values)}>
                    Yes
                  </Button>
                  <Button className="modal-content__button" onClick={() => setIsDeleteModalOpen(false)}>
                    No
                  </Button>
                </div>
              </div>
            </Modal>
          </form>
        )}
      />
    </div>
  )
}

export default AddEditContractor
