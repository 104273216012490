import { useMutation } from '@tanstack/react-query'
import { Input } from 'components'
import { Button } from 'components/Button'
import { RESET_PASSWORD } from 'graphql'
import React, { useEffect } from 'react'
import { Form } from 'react-final-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useProgressiveBackground } from 'utility/hooks'
import './ResetPassword.scss'

const ResetPassword = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { style } = useProgressiveBackground()

  const { mutate: resetPassword } = useMutation(RESET_PASSWORD, {
    onSuccess: () => {
      navigate('/login')
      toast('Password recovered successfully', { type: 'success' })
    },
    onError: err => {
      toast(err.response.data.message, { type: 'error' })
    },
  })

  useEffect(() => {
    if (!state) {
      navigate('/login')
    }
  }, [])

  return (
    <div className="reset-password">
      <div className="container" style={style}>
        <div className="reset-password-area">
          <div className="reset-password-area--background" style={style}>
            <Form
              onSubmit={value =>
                resetPassword({
                  password: value?.password,
                  password_confirmation: value?.retypedPassword,
                  reset_password_token: state?.token,
                })
              }
              render={({ handleSubmit }) => (
                <form className="form" onSubmit={handleSubmit}>
                  <h2 className="form__title">Reset Password</h2>
                  <Input name="password" label="Type Password" type="password" required />
                  <Input name="retypedPassword" labe="Retype Password" type="password" required />
                  <Button type="submit">Reset</Button>
                </form>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
