import { useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useImmer } from 'use-immer'

import { Context } from 'context'

import { MapLarge, MapMedium, MapSmall } from 'assets/images/background'

export const useAppContext = () => {
  const context = useContext(Context)
  return context
}

/**
 * @param {[String]} sources image sources in order from lowest resolution to highest
 * @param {String} [fallback] (optional) fallback image source if all others fail.
 * @returns {String} the highest resolution image source that has loaded.
 */
export const useProgressiveImage = (sources, fallback) => {
  const [srcLoaded, setSrcLoaded] = useImmer(new Array(sources?.length))
  const [highestRes, setHighestRes] = useState({ src: null, i: -1 })

  useEffect(() => {
    sources?.forEach?.((src, i) => {
      const img = new Image()
      img.src = src
      img.onload = () => setSrcLoaded(draft => void (draft[i] = src))
      return
    })
  }, [setSrcLoaded, sources])

  useEffect(() => {
    setHighestRes(highestRes => srcLoaded.reduce((a, c, i) => (a.i < i && !!c ? { src: c, i } : a), highestRes))
  }, [setHighestRes, srcLoaded])

  return highestRes?.src || fallback || null
}

export const useProgressiveBackground = () => ({
  style: { backgroundImage: `url(${useProgressiveImage([MapSmall, MapMedium, MapLarge], MapLarge)})` },
})

export const useAcceptPaymentRedirect = handlePasswordPage => {
  const [params] = useSearchParams()

  useEffect(() => {
    const searchParamsObject = Object.fromEntries(params.entries())
    if (searchParamsObject['success'] === 'true') {
      toast(
        <div style={{ display: 'grid', gridTemplateRows: 'auto auto auto', gap: '.3em' }}>
          <h3>Payment Successful</h3>
          <p>Please check your email for confirmation.</p>
          <p style={{ opacity: '.5', fontSize: '.7em' }}>Click this notification to dismiss this message.</p>
        </div>,
        { type: 'success', toastId: 'user-payment-success', position: 'top-center', autoClose: false }
      )
    }
    if (searchParamsObject['canceled'] === 'true') {
      toast(
        <div style={{ display: 'grid', gridTemplateRows: 'auto auto auto', gap: '.3em' }}>
          <h3>Payment Canceled</h3>
          <p>You will not be charged. If this was a mistake, please try again.</p>
          <p style={{ opacity: '.5', fontSize: '.7em' }}>Click this notification to dismiss this message.</p>
        </div>,
        {
          type: 'info',
          toastId: 'user-payment-cancel',
          position: 'top-center',
          autoClose: false,
        }
      )
    }
  }, [params])

  handlePasswordPage?.()
}
