import { DropFileIcon, XlsFileIcon } from 'assets/icons'
import clsx from 'clsx'
import { Button } from 'components/Button'
import { UploadFileLoader } from 'components/UploadFileLoader'
import React, { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { Field, useField } from 'react-final-form'
import { toast } from 'react-toastify'
import './Dropzone.scss'

const Dropzone = ({ name, required, fileExtension, parseXLSX, accept }) => {
  const field = useField(name)
  const [isLoading, setIsLoading] = useState(false)

  const { acceptedFiles, fileRejections, getRootProps, open, getInputProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    accept,
    onDrop: files => {
      setIsLoading(true)
      if (files) {
        field.input.onChange(files)
        if (parseXLSX) {
          parseXLSX(files[0])
        }
      }
    },
  })

  useEffect(() => {
    if (fileRejections.length > 0) {
      toast('Wrong file format', { type: 'error' })
    }
  }, [fileRejections?.length])

  const handleOpen = e => {
    e?.preventDefault()
    e?.stopPropagation()
    open(e)
  }

  const acceptedFileItems = acceptedFiles.map(file => (
    <span className="dropzone__file-name" key={file.path}>
      {file.path}
    </span>
  ))

  return (
    <Field
      name={name}
      type="radio"
      validate={value => {
        if (!value && required) {
          return 'This is a Required Field'
        }
      }}
    >
      {({ meta }) => (
        <>
          <section className={clsx('dropzone-container', meta.error && meta.touched && 'dropzone-container--error')}>
            {isLoading ? (
              <UploadFileLoader callbackFn={() => setIsLoading(false)} />
            ) : (
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                {acceptedFiles?.length === 0 ? (
                  <DropFileIcon />
                ) : (
                  <>
                    <XlsFileIcon /> {acceptedFileItems}
                  </>
                )}
                <p className="dropzone__description">
                  Drag {acceptedFiles?.length !== 0 && 'new'} {fileExtension} file here
                </p>
                <p className="dropzone__or">OR</p>
                <Button type="button" onClick={handleOpen}>
                  Browse
                </Button>
              </div>
            )}
            {meta.error && meta.touched && <span className="dropzone__error">*{meta.error}</span>}
          </section>
        </>
      )}
    </Field>
  )
}

export default Dropzone
