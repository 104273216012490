import { Form } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAppContext } from 'utility/hooks'

import { Button, Input } from 'components'

import { CentroHorizontalLogo } from 'assets/logos'
import './Password.scss'

const Password = () => {
  const { actions } = useAppContext(),
    navigate = useNavigate()

  const checkPassword = value => {
    if (value.password !== 'CENTRO_NOW_23') return void toast('Incorrect password', { type: 'error' })
    actions.setPassOk()
    navigate('/login', { replace: true })
  }

  return (
    <div className="password-protected">
      <h1 className="password-protected__title">
        <span>In order to access this website, you must enter the access password.</span>
      </h1>
      <h4 className="password-protected__subtitle">
        <span>
          For more information contact <a href="mailto:admin@centronow.com">admin@centronow.com</a>
        </span>
      </h4>
      <Form
        onSubmit={value => checkPassword(value)}
        render={({ handleSubmit, submitting, values }) => (
          <form className="form" onSubmit={handleSubmit}>
            <Input type="password" name="password" noValidate />
            <Button type="submit" disabled={submitting}>
              Submit
            </Button>
            <div className="about">
              <CentroHorizontalLogo />
              <p>© Centro Group LLC, 2023. All Rights Reserved.</p>
            </div>
          </form>
        )}
      />
    </div>
  )
}

export default Password
