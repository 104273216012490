import { DeleteIcon, DocShowIcon } from 'assets/icons'
import clsx from 'clsx'

export const renderColumns = deleteMutation => [
  {
    accessorFn: row => row?.dateUploaded,
    header: 'Upload Date',
    id: 'created_at',
  },
  {
    accessorFn: row => row?.title,
    header: 'Document Name',
    id: 'title',
  },
  {
    accessorFn: row => row?.documentType?.name,
    header: 'Document Type',
    id: 'document_type_name',
  },
  {
    accessorFn: row => row.status,
    header: 'Status',
    id: 'status',
    cell: cell => (
      <div
        className={clsx(
          'contractor-table__expiring',
          cell.getValue().includes('30') && 'contractor-table__expiring--30',
          cell.getValue().includes('60') && 'contractor-table__expiring--60',
          cell.getValue().includes('90') && 'contractor-table__expiring--90',
          cell.getValue().includes('Current') && 'contractor-table__expiring--current'
        )}
      >
        {cell.getValue()}
      </div>
    ),
  },
  {
    accessorFn: row => ({ url: row?.url, id: row?.id }),
    id: 'url',
    header: '',
    cell: cell => (
      <div className="column-icon-container">
        <button className="column-icon column-icon--contractor" onClick={() => window.open(cell.getValue().url, '_blank')} >
          <DocShowIcon />
          <span>View</span>
        </button>
        <div className="column-icon column-icon--contractor">
          <DeleteIcon
            onClick={() => {
              const documentToDelete = cell.getValue().id
              deleteMutation({ input: { destroyDocumentInput: { id: documentToDelete } } })
            }}
          />
          <span>Delete</span>
        </div>
      </div>
    ),
  },
]
