import { Outlet } from 'react-router-dom'
import { useAppContext } from 'utility/hooks'

import Password from './Password'

export const PasswordRoute = ({ invert }) => {
  const { passOk } = useAppContext()

  return passOk ? <Outlet /> : <Password />
}
