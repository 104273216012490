import React from 'react'
import './FilterItem.scss'
import { ArrowDownIcon, ArrowUpIcon } from 'assets/icons'

const variantsMap = {
  dateCreated: 'Date Created',
  documentName: 'Document Name',
  dateRange: 'Date Range',
  company: 'Company',
  contact: 'Contact',
  createdAt: 'Upload Date',
  documentStatus: 'Document Status',
  documentType: 'Document Type',
  markets: 'Markets',
}

const FilterItem = ({ variant, children, handleChangeOpenFilters, openFiltersArray }) => {
  return (
    <div className="filters-filter">
      <div className="filters-filter__title" onClick={() => handleChangeOpenFilters(variant)}>
        {variantsMap[variant]}
        {openFiltersArray.includes(variant) ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </div>
      {openFiltersArray.includes(variant) && children}
    </div>
  )
}

export default FilterItem
