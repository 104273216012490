import React, { useContext, useState } from 'react'

import { NavLink, useNavigate } from 'react-router-dom'
import { useSpring, animated } from '@react-spring/web'

import { BusinessNetworkIcon, DollarSignIcon, ExportIcon, HomeIcon, SettingsIcon } from 'assets/icons'
import MenuButton from './MenuButton'

import './Sidebar.scss'
import { Context } from 'context'
import { Modal } from 'components/Modal'
import { Button } from 'components/Button'
import { toast } from 'react-toastify'
import { CentroHorizontalLogo, CentroVerticalLogo } from 'assets/logos'

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false)
  const { actions, user } = useContext(Context)
  const navigate = useNavigate()

  const { sidebarWidth, sidebarTextWidth } = useSpring({
    sidebarTextWidth: !isOpen ? '0%' : '100%',
    sidebarWidth: !isOpen ? '7.5rem' : '22rem',
    config: {
      friction: 25,
      tension: 300,
    },
  })

  const handleLogout = () => {
    actions.logout()
    navigate('/login')
    toast('User logged out successfully', { type: 'success' })
  }
  return (
    <animated.header style={{ width: sidebarWidth }} className="sidebar">
      <button className={'sidebar-button sidebar-button--menu'} onClick={() => setIsOpen(!isOpen)}>
        <MenuButton isOpen={isOpen} />
      </button>
      <nav className="sidebar-navigation__wrapper">
        <ul className="sidebar-navigation">
          <li>
            <NavLink to="/" className={'sidebar-button sidebar-button--nav'}>
              <HomeIcon className="sidebar-button__icon" />
              <animated.span style={{ width: sidebarTextWidth }} className="sidebar-button__text">
                Home
              </animated.span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/contractors" className={'sidebar-button sidebar-button--nav'}>
              <BusinessNetworkIcon className="sidebar-button__icon" />
              <animated.span style={{ width: sidebarTextWidth }} className="sidebar-button__text">
                Contractor List
              </animated.span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/settlements" className={'sidebar-button sidebar-button--nav'}>
              <DollarSignIcon className="sidebar-button__icon" />
              <animated.span style={{ width: sidebarTextWidth }} className="sidebar-button__text">
                Settlements
              </animated.span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/account" className={'sidebar-button sidebar-button--nav'}>
              <SettingsIcon className="sidebar-button__icon" />
              <animated.span style={{ width: sidebarTextWidth }} className="sidebar-button__text">
                Account
              </animated.span>
            </NavLink>
          </li>
          <li>
            <button className={'sidebar-button sidebar-button--exit'} onClick={() => setIsLogoutModalOpen(true)}>
              <ExportIcon className="sidebar-button__icon" />
              <animated.span style={{ width: sidebarTextWidth }} className="sidebar-button__text">
                Logout
              </animated.span>
            </button>
          </li>
        </ul>
      </nav>
      {isOpen ? (
        <div className="sidebar-logo sidebar-logo--is-open">
          <CentroHorizontalLogo />
          <div className="sidebar-rights">
            <p>© Centro Group LLC, 2023.</p> <p>All Rights Reserved.</p>
          </div>
        </div>
      ) : (
        <div className="sidebar-logo">
          <CentroVerticalLogo />
        </div>
      )}
      <Modal isOpen={isLogoutModalOpen} onClose={() => setIsLogoutModalOpen(false)}>
        <div className="modal-content modal-content-logout">
          <p className="modal-content-logout__question">Are you sure you want to log out</p>
          <h2 className="modal-content-logout__name">{user?.first_name} {user?.last_name}</h2>
          <div className="modal-content__buttons">
            <Button className="modal-content__button" onClick={handleLogout}>
              Yes
            </Button>
            <Button className="modal-content__button" onClick={() => setIsLogoutModalOpen(false)}>
              No
            </Button>
          </div>
        </div>
      </Modal>
    </animated.header>
  )
}

export default Sidebar
