import { Verification, VerificationInput } from 'components'
import { useEffect } from 'react'
import './PhoneVerification.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { SEND_EMAIL_PHONE_VERIFICATION, VERIFY_EMAIL_PHONE_CODE } from 'graphql'
import { toast } from 'react-toastify'

const PhoneVerification = () => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const { mutate: sendPhoneMutation } = useMutation(SEND_EMAIL_PHONE_VERIFICATION, {
    onError: () => {
      toast('Unexpected error occurred, try again', { type: 'error' })
    },
  })
  const { mutate: verifyPhoneMutation } = useMutation(VERIFY_EMAIL_PHONE_CODE, {
    onSuccess: data => {
      if (data?.data?.success) {
        if (isResetPassword) {
          navigate('/reset-password', { state: { token: data.data.token } })
          toast('User verified successfully', { type: 'success' })
        } else {
          navigate('/login')
          toast('User created successfully. Please log in now.', { type: 'success' })
        }
      }
    },
    onError: () => {
      toast('Invalid OTP code.', { type: 'error' })
    },
  })
  const { id, phoneWithMask, isResetPassword } = state

  useEffect(() => {
    sendPhoneMutation({ id: id, channel: 'sms' })
  }, [id])

  if (!state) {
    navigate('/login')
    return
  }

  const handleSubmit = code => {
    verifyPhoneMutation({ id: id, code: code, channel: 'sms', reset_password: isResetPassword })
  }

  return (
    <Verification
      help={true}
      resend={true}
      padding={true}
      onResend={() => sendPhoneMutation({ id: id, channel: 'sms' })}
    >
      <VerificationInput
        title="Phone Verification"
        subTitle={`Please enter the verification code sent to ${phoneWithMask}`}
        buttonText="Verify Code"
        buttonAction={handleSubmit}
      />
    </Verification>
  )
}

export default PhoneVerification
