export const GET_BROKER = `
    query Broker {
      broker {
        id
        email
        firstName
        lastName
        mobilePhone
        companyName
        companyPhone
        companyPhoneExt
        emailNotification
        smsNotification
        notificationDays
        createdAt
      }
    }
    `

export const DOCUMENTS_FILTER_QUERY = `
query documentFiltersQuery {
  documentFilters {
    market {
      id
      name
    }
    documentType {
      id
      name
    }
    clientId
    companyName
    contactName
    contactMobilePhone
    contactEmail
    motorCarrier
    usdot
  }
}
`

export const CONTRACTORS_FILTERS_QUERY = `
{
  contractorsFilters {
    companyName
    contactEmail
    contactLastName
    contactMobilePhone
    contactName
    id
    market {
      id
      name
    }
    motorCarrier
    usdot
  }
}`

export const CONTRACTOR_FILTERS_QUERY = `
query contractorFiltersQuery ($id: ID!) {
  contractorFilters(id: $id) {
    documentName
    documentType
  }
}`

export const SETTLEMENTS_FILTER_QUERY = `
  {
    spreadsheetFilters {
      name
    }
  }
  `

export const SEND_NOTIFICATION = `
  query sendNotificationQuery ($ids: [ID!]!) {
    sendNotification (ids: $ids) 
  }
  `

export const DOCUMENTS_QUERY = `
 query documentsQuery (
  $page: Int
  $limit: Int
  $markets: String
  $documentTypeId: ID
  $clientId: ID
  $motorCarrier: String
  $usdot: String
  $status: [String!]
  $contactName: String
  $contactMobilePhone: String
  $contactEmail: String
  $companyName: String
  $createdAt: String
  $text: String
  $orderBy: [String!]
) { 
  documents(
      page: $page
      limit: $limit
      market: $markets
      documentTypeId: $documentTypeId
      clientId: $clientId
      motorCarrier: $motorCarrier
      usdot: $usdot
      status: $status
      contactName: $contactName
      contactMobilePhone: $contactMobilePhone
      companyName: $companyName
      contactEmail: $contactEmail
      createdAt: $createdAt
      text: $text
      orderBy: $orderBy
  ) {
    collection {
      url
      status
      motorCarrier
      dateUploaded
      usdot
      client {
          id
          companyName
          contactName
          contactMobilePhone
          contactEmail
          markets {
              name
          }
      }
      documentType {
          id
          name
      }
  }
  metadata {
      totalPages
      totalCount
      currentPage
      limitValue
  }
  }
}`

export const DASHBOARD_GRAPHICS_QUERY = `{
  dashboardGraphics {
      documentationStatus {
          expired
          expiring30Days
          expiring60Days
          expiring90Days
          current
          total
      }
      contractorCompliance {
          total
          current
          documentationNeeded
      }
      latestSettlement {
          id
          title
          url
          createdAt
      }
  }
}`

export const SETTLEMENTS_QUERY = `
  query settlementQuery (
    $page: Int
    $limit: Int
    $documentName: String
    $createdAt: ISO8601DateTime
    $startDate: ISO8601Date
    $endDate: ISO8601Date
    $orderBy: [String!]
    $text: String
  ) { 
    spreadsheet(
    page: $page
    limit: $limit
    name: $documentName
    createdAt: $createdAt
    startDate: $startDate
    endDate: $endDate
    orderBy: $orderBy
    text: $text
  ) {
    collection {
      createdAt
      endDate
      id
      name
      startDate
      url
    }
    metadata {
      currentPage
      limitValue
      totalCount
      totalPages
    }
  }
}
`

export const TEMPLATES_QUERY = `
{
  templates {
    name
    url
  }
}
  `

export const CONTRACTOR_QUERY = `
query contractorQuery ($id: ID!) {
  contractor (id: $id) {
    businessAddress
    businessCity
    businessExt
    businessPhone
    businessState
    businessZip
    companyName
    contactEmail
    contactLastName
    contactMobilePhone
    contactName
    id
    markets {
      id
      name
    }
  }
}
`

export const CONTRACTOR_WITH_DOCS_QUERY = `
query contractorQuery (
  $id: ID!
  $page: Int
  $limit: Int
  $status: [String!]
  $documentName: String
  $documentType: String
  $createdAt: String
  $text: String
  $orderBy: [String!]
  ) {
  contractor (id: $id) {
    businessAddress
    businessCity
    businessExt
    businessPhone
    businessState
    businessZip
    companyName
    contactEmail
    contactLastName
    contactMobilePhone
    contactName
    id
    markets {
      id
      name
    }
    motorCarrier
    usdot
    documentationStatus {
      total
      expired
      expiring30Days
      expiring60Days
      expiring90Days
      current
  }
  documents(
    page: $page,
    limit: $limit,
    documentStatus: $status
    documentName: $documentName
    documentType: $documentType
    dateCreated: $createdAt
    text: $text
    orderBy: $orderBy
  )
    {
      collection {
        url
        status
        motorCarrier
        dateUploaded
        usdot
        title
        id
        client {
            id
            companyName
            contactName
            contactMobilePhone
            contactEmail
            markets {
                name
            }
        }
        documentType {
            name
        }
    }
    metadata {
        totalPages
        totalCount
        currentPage
        limitValue
    }
  }
}
}
`

export const CONTRACTORS_QUERY = `query contractorsQuery (
  $page: Int
  $limit: Int
  $clientId: [ID!]
  $companyName: String
  $motorCarrier: String
  $contactName: String
  $contactMobilePhone: String
  $contactEmail: String
  $usdot: String
  $markets: String
  $text: String
  $orderBy: [String!]
  $createdAt: String

  ) {
  contractors(
    page: $page
    limit: $limit
    id: $clientId
    contactName: $contactName
    contactMobilePhone: $contactMobilePhone
    contactEmail: $contactEmail
    companyName: $companyName
    motorCarrier: $motorCarrier
    usdot: $usdot
    market: $markets
    text: $text
    createdAt: $createdAt
    orderBy: $orderBy
  ) {
    collection {
      companyName
      contactEmail
      contactLastName
      contactMobilePhone
      contactName
      documents
      createdAt
      id
      markets {
        id
        name
      }
      motorCarrier
      status {
        type
        value
      }
      usdot
    }
    metadata {
      currentPage
      limitValue
      totalCount
      totalPages
    }
  }
}
`

export const GET_DOCUMENT_TYPES = `
    query GetDocumentTypes {
  documentTypeCategories {
    documentType {
      id
      name
    }
    documentTypesToDelete {

      id
      name
    }
    id
    name
  }
}
`
