import React from 'react'
import { Outlet } from 'react-router-dom'

import { Sidebar } from 'components'
import { useProgressiveBackground } from 'utility/hooks'

import './Dashboard.scss'

const Dashboard = () => {
  return (
    <div className="dashboard" {...useProgressiveBackground()}>
      <Sidebar />
      <main className="main">
        <Outlet />
      </main>
    </div>
  )
}

export default Dashboard
