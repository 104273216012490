import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import './Toggle.scss'
import { useSpring, animated } from '@react-spring/web'
import { Field } from 'react-final-form'
import { useField } from 'react-final-form'

const Toggle = ({ name, label, disabled, ...props }) => {
  const [isChecked, setIsChecked] = useState(false)

  const field = useField(name)

  const { translate } = useSpring({
    translate: isChecked ? 'translateX(5.2rem)' : 'translateX(0rem)',
    config: {
      duration: 10,
    },
  })

  useEffect(() => {
    field?.input.onChange(field?.input?.value)
    setIsChecked(field?.input?.value)
  }, [field?.input?.value])

  const handleChange = (e, input) => {
    input.onChange(e.target.checked)
    setIsChecked(e.target.checked)
  }

  return (
    <Field type="checkbox" name={name}>
      {({ input }) => {
        return (
          <div className="toggle">
            <input
              className="toggle-input"
              {...input}
              {...props}
              name={name}
              id={name}
              type={'checkbox'}
              onChange={e => handleChange(e, input)}
              disabled={disabled}
            />
            <label className={clsx('toggle-label', isChecked && 'toggle-label--checked')} htmlFor={name}>
              <animated.div className="toggle-label__switcher" style={{ transform: translate }}></animated.div>
              {isChecked && <span className="toggle-label__text">YES</span>}
              {!isChecked && <span className="toggle-label__text">NO</span>}
            </label>
          </div>
        )
      }}
    </Field>
  )
}

export default Toggle
