import { useMemo } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useAppContext } from 'utility/hooks'

export const PrivateRoute = ({ invert }) => {
  const { user } = useAppContext()
  const { pathname } = useLocation()
  const whiteList = [
    '/login',
    '/signup',
    '/accountVerification',
    '/phoneVerification',
    '/emailVerification',
    '/qrVerification',
  ]

  const isAuthenticated = useMemo(() => {
    return user?.auth_token
  }, [user])

  return isAuthenticated ? (
    invert ? (
      pathname !== '/' ? (
        <Navigate to={'/'} />
      ) : (
        <Outlet />
      )
    ) : (
      <Outlet />
    )
  ) : !whiteList.includes(pathname) ? (
    whiteList.includes(pathname) ? (
      <Navigate to={pathname} />
    ) : (
      <Navigate to={'/login'} />
    )
  ) : (
    <Outlet />
  )
}
