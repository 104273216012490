import { Button } from 'components/Button'
import {  useNavigate } from 'react-router-dom'
import './VerificationItem.scss'

const VerificationItem = ({ icon, data, title, message, buttonText, link }) => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate(link, { state: data })
  }
  return (
    <div className="verification-item">
      <div className="verification-item__icon">{icon}</div>
      <div className="verification-item__content">
        <h2>{title}</h2>
        <p>{message}</p>
      </div>
      <div className="verification-item__button">
        <Button onClick={handleClick}>{buttonText}</Button>
      </div>
    </div>
  )
}

export default VerificationItem
