export const keys = {
  LOG_OUT: 'LOG_OUT',
  SET_AUTHENTICATED: 'SET_AUTHENTICATED',
  SET_STATE: 'SET_STATE',
  SET_PASS_OK: 'SET_PASS_OK',
  SET_PASS_NOT_OK: 'SET_PASS_NOT_OK',
}

const local = localStorage.getItem('_centro_auth')

export const initialState = {
  passOk: !!localStorage?.getItem?.('_centro_auth') || localStorage?.getItem?.('passOk') === 'true' || false,
  isLoggedIn: false,
  user: local ? JSON.parse(local) : null,
}
