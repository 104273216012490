import { useContext } from 'react'
import { toast } from 'react-toastify'
import { useQuery } from '@tanstack/react-query'
import { CONTRACTORS_QUERY } from 'graphql'

import { Context } from 'context'

import { xlsxMJSScriptLink } from './consts'
import { getLastDayOfWeek } from './helpers'

import centroXlsxTemplate from 'assets/xlsx/centroXlsxTemplate.xlsx'

const currencyFormat = '_("$"* #,##0.00_);_("$"* (#,##0.00);_("$"* "-"??_);_(@_)'

const colsSizes = [
  { wpx: 110 },
  { wpx: 140 },
  { wpx: 140 },
  { wpx: 110 },
  { wpx: 110 },
  { wpx: 120 },
  { wpx: 110 },
  { wpx: 110 },
  { wpx: 110 },
  { wpx: 110 },
  { wpx: 110 },
  { wpx: 110 },
]

export const useDownloadXLSXTemplate = () => {
  const { actions, xlsx, user } = useContext(Context)

  const { refetch: getContractorsData, isLoading } = useQuery(
    [
      {
        variables: { limit: 9999 },
        query: CONTRACTORS_QUERY,
      },
    ],
    /**
     * @type {import('@tanstack/react-query').UseQueryOptions}
     */
    {
      enabled: false,
      onError: () => {
        toast('Unexpected error occurred, try again', { type: 'error', toastId: 'download-info' })
      },
    }
  )

  return async () => {
    try {
      let xlsxLoaded = xlsx
      if (!xlsxLoaded) {
        xlsxLoaded = await import(/*webpackIgnore: true*/ xlsxMJSScriptLink)
        actions.updateState({ name: 'xlsx', value: { ...xlsxLoaded } })
      }

      //get an empty XLSX file from local
      const response = await fetch(centroXlsxTemplate)
      const blob = await response.blob()
      const loadedFile = xlsxLoaded.read(await blob.arrayBuffer())

      // get the contractors data
      const contractors = await await getContractorsData()
      const contractorsCollection = contractors?.data?.data?.data?.contractors?.collection
      const contractorsMetadata = contractors?.data?.data?.data?.contractors?.metadata

      const currentSheet = loadedFile.Sheets['Sheet1']

      // set width of each column
      currentSheet['!cols'] = colsSizes

      // fill the excel with data
      contractorsCollection.forEach((el, i) => {
        currentSheet[`A${i + 2}`] = { v: el?.id }
        currentSheet[`B${i + 2}`] = { v: el?.contactLastName }
        currentSheet[`C${i + 2}`] = { v: el?.contactName }
        currentSheet[`D${i + 2}`] = { v: el?.companyName }
      })

      // fill currency fields with sums
      for (let i = 2; i < 50; i++) {
        currentSheet[`E${i}`] = {
          z: currencyFormat,
          t: 'n',
        }
        currentSheet[`F${i}`] = {
          z: currencyFormat,
          t: 'n',
        }
        currentSheet[`G${i}`] = {
          z: currencyFormat,
          t: 'n',
        }
        currentSheet[`H${i}`] = {
          z: currencyFormat,
          t: 'n',
        }
        currentSheet[`I${i}`] = {
          z: currencyFormat,
          t: 'n',
        }
        currentSheet[`J${i}`] = {
          z: currencyFormat,
          t: 'n',
        }
        currentSheet[`K${i}`] = {
          f: `SUM(F${i}:J${i})`,
          z: currencyFormat,
          t: 'n',
        }
        currentSheet[`L${i}`] = {
          f: `SUM(E${i}-K${i})`,
          v: 0,
          z: currencyFormat,
          t: 'n',
        }
      }
      currentSheet['E49'] = { f: `SUM(E${2}:E${48})`, z: currencyFormat, t: 'n' }
      currentSheet['F49'] = { f: `SUM(F${2}:F${48})`, z: currencyFormat, t: 'n' }
      currentSheet['G49'] = { f: `SUM(G${2}:G${48})`, z: currencyFormat, t: 'n' }
      currentSheet['H49'] = { f: `SUM(H${2}:H${48})`, z: currencyFormat, t: 'n' }
      currentSheet['I49'] = { f: `SUM(I${2}:I${48})`, z: currencyFormat, t: 'n' }
      currentSheet['J49'] = { f: `SUM(J${2}:J${48})`, z: currencyFormat, t: 'n' }

      // fill info about count
      currentSheet['B49'] = {
        v: contractorsMetadata?.totalCount,
      }

      // fill info about the user
      currentSheet['A55'] = { v: `${user?.first_name} ${user?.last_name}` }
      currentSheet['A56'] = { v: 'Commissions and Expenses Settlement' }
      currentSheet['A57'] = { v: 'WEEK ENDING: ' + getLastDayOfWeek() }

      xlsxLoaded.writeFileXLSX(
        loadedFile,
        `spreadsheet-template-${user?.first_name}-${user?.last_name}-${new Date().toLocaleDateString()}.xlsx`
      )
    } catch (error) {
      toast('Unexpected error occurred, try again', { type: 'error', toastId: 'download-info' })
    }
  }
}
