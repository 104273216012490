import { snakeCaseToTitleCase } from '../../utility/helpers'
import { CloseCircleIcon } from 'assets/icons'
import React from 'react'
import './FilterBadge.scss'

const filtersNamesMap = {
  dateCreated: 'Date Created',
  documentName: 'Document Name',
  dateRangeFrom: 'Date Range From',
  dateRangeTo: 'Date Range To',
  companyName: 'Company Name',
  clientId: 'Client ID',
  motorCarrier: 'Motor Carrier #',
  usdot: 'USDOT#',
  contactName: 'Contact Name',
  contactMobilePhone: 'Contact Mobile Phone',
  contactEmail: 'Contact Email',
  createdAt: 'Created Date',
  documentTypeId: 'Document Type',
  markets: 'Markets',
  text: 'Search',
  expiring: 'Document Status',
  page: 'Page',
  orderBy: 'Sorting',
}

const documentStatusesMap = {
  current: 'Current',
  expired: 'Expired',
  days30: 'Expiring 30 days',
  days60: 'Expiring 60 days',
  days90: 'Expiring 90 days',
}

const excludedFieldsFromBadges = ['limit', 'orderBy', 'page']

const orderNamesMap = order => {
  const value = order[0] && order[0]
  const title = snakeCaseToTitleCase(value)
  return title
}

const FilterBadge = ({ fieldName, value, onDelete }) => {
  const isExpiringValueEmpty =
    fieldName.includes('expiring') && Object.values(value).filter(el => el === true).length === 0

  if (!value || value.length === 0 || excludedFieldsFromBadges.includes(fieldName)) {
    return
  }

  if (isExpiringValueEmpty) {
    return
  }
  return (
    <div className="filter-badge">
      <span>
        {fieldName.includes('expiring') &&
          Object.values(value).filter(el => el === true).length > 0 &&
          `${filtersNamesMap[fieldName]} : ${Object.keys(value)
            .filter(key => value[key])
            .map(el => documentStatusesMap[el])
            .join(', ')}`}
      </span>
      <span>
        {!fieldName.includes('expiring') &&
          fieldName.includes('orderBy') &&
          `${filtersNamesMap[fieldName]} : ${orderNamesMap(value)}`}
      </span>

      <span>
        {!fieldName.includes('expiring') &&
          !fieldName.includes('orderBy') &&
          `${filtersNamesMap[fieldName]} : ${value}`}
      </span>

      <CloseCircleIcon onClick={onDelete} />
    </div>
  )
}

export default FilterBadge
