import { Verification, VerificationInput } from 'components'
import './QrVerification.scss'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { SEND_QR_VERIFICATION, VERIFY_QR_CODE } from 'graphql'
import { Spinner } from 'components/Spinner'
import { toast } from 'react-toastify'

const QrVerification = () => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const [verificationData, setVerificationData] = useState(null)
  const { mutate: sendQrMutation, isLoading } = useMutation(SEND_QR_VERIFICATION, {
    onSuccess: data => {
      setVerificationData(data.data)
    },
    onError: () => {
      toast('Unexpected error occurred, try again', { type: 'error' })
    },
  })
  const { mutate: verifyQrCode } = useMutation(VERIFY_QR_CODE, {
    onSuccess: data => {
      if (data?.data?.success) {
        if (isResetPassword) {
          navigate('/reset-password', { state: { token: data.data.token } })
          toast('User verified successfully', { type: 'success' })
        } else {
          navigate('/login')
          toast('User created successfully. Please log in now.', { type: 'success' })
        }
      }
    },
    onError: () => {
      toast('Invalid OTP code.', { type: 'error' })
    },
  })
  const { id, isResetPassword } = state

  useEffect(() => {
    sendQrMutation({ id: id })
  }, [id])

  if (!state) {
    navigate('/login')
    return
  }
  const handleSubmit = code => {
    verifyQrCode({ id: id, code: code, reset_password: isResetPassword })
  }

  if (isLoading) {
    return <Spinner centered />
  }

  return (
    <Verification className="verification--qr" qrKey={verificationData?.qr_key}>
      <VerificationInput
        title="Google Authenticate Setup"
        message="Just scan this image with the Google Authenticator app then enter the generated code below:"
        imageSrc={verificationData?.qr_image}
        imageAlt="Google Authenticator QR Code"
        subTitle="Six-digit authentication code"
        buttonText="Verify Code"
        buttonAction={handleSubmit}
      />
    </Verification>
  )
}

export default QrVerification
