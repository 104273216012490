import React, { useContext, useState } from 'react'
import { Header, Input } from 'components'
import { Toggle } from 'components/Toggle'
import { RectangleCheckbox } from 'components/RectangleCheckbox'
import { EmailEnvelopeIcon, SMSIcon } from 'assets/icons'
import { Form } from 'react-final-form'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Modal } from 'components/Modal'
import { Button } from 'components/Button'
import { GET_BROKER, UPDATE_BROKER, DESTROY_BROKER } from 'graphql'
import produce from 'immer'
import { useNavigate } from 'react-router-dom'
import { Context } from 'context'

import './Account.scss'
import { toast } from 'react-toastify'
import { CentroHorizontalLogo } from 'assets/logos'

const Account = () => {
  const { actions } = useContext(Context)
  const navigate = useNavigate()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [accountValues, setAccountValues] = useState({})
  const {} = useQuery(
    [
      {
        query: GET_BROKER,
      },
    ],
    {
      onSuccess: brokerData => {
        const data = brokerData?.data?.data?.broker
        mapBrokerData(data)
      },
    }
  )

  const { mutate: updateBroker } = useMutation(UPDATE_BROKER, {
    onSuccess: brokerData => {
      const data = brokerData?.data?.data?.updateBroker?.broker
      if (data) {
        mapBrokerData(data)

        const userData = JSON.parse(localStorage.getItem('_centro_auth'))
        const newAuthenticationData = {
          ...userData,
          first_name: data?.firstName,
          last_name: data?.lastName,
          company_name: data?.companyName,
        }
        localStorage.setItem('_centro_auth', JSON.stringify(newAuthenticationData))
        actions.setAuthenticated(newAuthenticationData)

        toast('Account changes saved successfully', { type: 'success' })
      } else if (brokerData?.data?.data?.updateBroker?.errors) {
        brokerData?.data?.data?.updateBroker?.errors.forEach(error => {
          toast(error, { type: 'error' })
        })
      }
    },
  })
  const { mutate: destroyBroker } = useMutation(DESTROY_BROKER, {
    onSuccess: () => {
      actions.logout()
      navigate('/login')
      toast('User removed successfully', { type: 'success' })
    },
  })

  const mapBrokerData = data => {
    const values = produce({ expiring: {}, ...data }, draft => {
      if (data?.notificationDays?.includes(0)) {
        draft.expiring.expired = true
      }
      if (data?.notificationDays?.includes(30)) {
        draft.expiring.days30 = true
      }
      if (data?.notificationDays?.includes(60)) {
        draft.expiring.days60 = true
      }
      if (data?.notificationDays?.includes(90)) {
        draft.expiring.days90 = true
      }
      if (data?.mobilePhone) {
        draft.mobilePhone = data?.mobilePhone?.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
      }
      if (data?.companyPhone) {
        draft.companyPhone = data?.companyPhone?.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
      }
    })
    setAccountValues(values)
  }

  const mapFormData = data => {
    return produce(data, draft => {
      draft.notificationDays = []
      if (data?.expiring?.expired) {
        draft?.notificationDays.push(0)
      }
      if (data?.expiring?.days30) {
        draft?.notificationDays.push(30)
      }
      if (data?.expiring?.days60) {
        draft?.notificationDays.push(60)
      }
      if (data?.expiring?.days90) {
        draft?.notificationDays.push(90)
      }
      if (data?.mobilePhone) {
        draft.mobilePhone = data?.mobilePhone?.replaceAll('-', '')
      }
      if (data?.companyPhone) {
        draft.companyPhone = data?.companyPhone?.replaceAll('-', '')
      }
      delete draft.expiring
      delete draft.retypedPassword
      delete draft.id
      delete draft.createdAt
    })
  }

  const handleSubmit = v => {
    const values = mapFormData(v)
    updateBroker({ input: { brokerInput: values } })
  }

  const handleDeleteAccount = () => {
    destroyBroker({ input: {} })
  }

  return (
    <div className="account">
      <div className="account-header">
        <Header />
        <CentroHorizontalLogo className="account-header__logo" />
      </div>
      <Form
        initialValues={accountValues}
        onSubmit={handleSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div className="glass account__content">
              <h2 className="account__title">Edit Account</h2>
              <div className="account__info">
                <div className="user">
                  <div className="user__fields-container">
                    <div className="user__field">
                      <Input name="firstName" label="First Name" type="text" required />
                    </div>
                    <div className="user__field">
                      <Input name="lastName" label="Last Name" type="text" required />
                    </div>
                    <div className="user__field">
                      <Input name="mobilePhone" label="Mobile Phone" type="tel" maxLenght={12} required />
                    </div>
                  </div>
                  <div className="user__fields-container user__fields-container--password-container">
                    <div className="user__field">
                      <Input autoComplete="new-password" name="password" label="Change Password" type="password" />
                    </div>
                    <div className="user__field">
                      <Input name="retypedPassword" label="Retype Password" type="password" />
                    </div>
                  </div>
                  <div className="user__fields-container">
                    <div className="user__field">
                      <Input name="companyName" label="Company Name" type="text" required />
                    </div>
                    <div className="user__field">
                      <Input name="email" label="Contact Email" type="email" required />
                    </div>
                    <div className="user__field">
                      <Input name="companyPhone" label="Business Phone" type="tel" maxLenght={12} required />
                    </div>
                    <div className="user__field">
                      <Input name="companyPhoneExt" label="Business Phone Ext" type="number" maxLenght={4} required />
                    </div>
                  </div>
                  <div className="account__save">
                    <Button>Save</Button>
                  </div>
                </div>
                <div className="notifications">
                  <h6 className="notifications__title">Notify Me When Documents Are</h6>
                  <div className="notifications__options">
                    <div className="notifications__option">
                      <RectangleCheckbox name="expiring.expired" label="Expired" />
                    </div>
                    <div className="notifications__option">
                      <RectangleCheckbox name="expiring.days30" label="Expiring (30 Days)" />
                    </div>
                    <div className="notifications__option">
                      <RectangleCheckbox name="expiring.days60" label="Expiring (60 Days)" />
                    </div>
                    <div className="notifications__option">
                      <RectangleCheckbox name="expiring.days90" label="Expiring (90 Days)" />
                    </div>
                  </div>
                  <div className="notifications-preferences">
                    <h6 className="notifications__title">Notifications Preferences</h6>
                    <div className="notifications-preferences__options">
                      <div className="notifications-preferences__option">
                        <div className="notifications-preferences__description">
                          <EmailEnvelopeIcon />
                          <p>Emails</p>
                        </div>
                        <Toggle name="emailNotification" />
                      </div>
                      <div className="notifications-preferences__option">
                        <div className="notifications-preferences__description">
                          <SMSIcon />
                          <p>SMS Text Messages</p>
                        </div>
                        <Toggle name="smsNotification" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      />
      <div className="centered-copyright account__centered-copyright">
        <button className="account__delete-button"  onClick={() => setIsDeleteModalOpen(!isDeleteModalOpen)}>
          Delete contractor
        </button>
        <p>© Centro Group LLC, 2023. All Rights Reserved.</p>{' '}
      </div>
      <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
        <div className="modal-content modal-content-delete-account">
          <p className="modal-content-delete-account__question">Are you sure you want to delete the account for</p>
          <h2 className="modal-content-delete-account__name">
            {accountValues?.firstName} {accountValues?.lastName}
          </h2>
          <div className="modal-content__buttons">
            <Button className="modal-content__button" onClick={handleDeleteAccount}>
              Yes
            </Button>
            <Button className="modal-content__button" onClick={() => setIsDeleteModalOpen(false)}>
              No
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Account
