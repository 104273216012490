import { useMutation } from '@tanstack/react-query'
import { Verification, VerificationInput } from 'components'
import { SEND_EMAIL_PHONE_VERIFICATION, VERIFY_EMAIL_PHONE_CODE } from 'graphql'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const EmailVerification = () => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const { mutate: sendEmailMutation } = useMutation(SEND_EMAIL_PHONE_VERIFICATION, {
    onError: () => {
      toast('Unexpected error occurred, try again', { type: 'error' })
    },
  })
  const { mutate: verifyEmailMutation } = useMutation(VERIFY_EMAIL_PHONE_CODE, {
    onSuccess: data => {
      if (data?.data?.success) {
        if (isResetPassword) {
          navigate('/reset-password', { state: { token: data.data.token } })
          toast('User verified successfully', { type: 'success' })
        } else {
          navigate('/login')
          toast('User created successfully. Please log in now.', { type: 'success' })
        }
      }
    },
    onError: () => {
      toast('Invalid OTP code.', { type: 'error' })
    },
  })
  const { id, email, isResetPassword } = state

  useEffect(() => {
    sendEmailMutation({ id: id, channel: 'email' })
  }, [id])

  if (!state) {
    navigate('/login')
    return
  }

  const handleSubmit = code => {
    verifyEmailMutation({ id: id, code: code, channel: 'email', reset_password: isResetPassword })
  }

  return (
    <Verification
      help={true}
      resend={true}
      padding={true}
      onResend={() => sendEmailMutation({ id: id, channel: 'email' })}
    >
      <VerificationInput
        title="Email Verification"
        subTitle={`Please enter the code we sent to ${email}`}
        buttonText="Verify Code"
        buttonAction={handleSubmit}
      />
    </Verification>
  )
}

export default EmailVerification
