import React, { useContext, useState } from 'react'
import { Form } from 'react-final-form'
import { toast } from 'react-toastify'
import { useQuery } from '@tanstack/react-query'
import { DASHBOARD_GRAPHICS_QUERY, DOCUMENTS_FILTER_QUERY, DOCUMENTS_QUERY } from 'graphql'
import produce from 'immer'
import { xlsxMinifiedScriptLink } from 'utility/consts'
import { useAcceptPaymentRedirect } from 'utility/hooks'

import { Header } from 'components'
import { Spinner } from 'components/Spinner'
import { Context } from 'context'

import { generateExcelFile, mapDocumentsToExcelRows } from './excelUtils'
import { HomeDocuments } from './parts/HomeDocuments'
import { HomeInformation } from './parts/HomeInformation'

import { CentroHorizontalLogo } from 'assets/logos'
import './Home.scss'

const Home = () => {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false)
  const [filters, setFilters] = useState({})
  const { actions, xlsx: xlsxLoaded } = useContext(Context)

  useAcceptPaymentRedirect()

  const prepareQueryFromFilters = filters => {
    return produce(filters, draft => {
      const notificationDays = []
      if (filters?.expiring?.current) {
        notificationDays.push('Current')
      }
      if (filters?.expiring?.expired) {
        notificationDays.push('Expired')
      }
      if (filters?.expiring?.days30) {
        notificationDays.push('Expiring (30 Days)')
      }
      if (filters?.expiring?.days60) {
        notificationDays.push('Expiring (60 Days)')
      }
      if (filters?.expiring?.days90) {
        notificationDays.push('Expiring (90 Days)')
      }

      if (filters?.expiring) {
        delete draft.expiring
      }
      draft.status = notificationDays
      draft.limit = filters?.limit || 5
    })
  }

  const { data: documentsFiltersData } = useQuery([
    {
      query: DOCUMENTS_FILTER_QUERY,
    },
  ])
  const { data: dashboardChartData, isLoading: dashboardChartDataLoading } = useQuery([
    {
      query: DASHBOARD_GRAPHICS_QUERY,
    },
  ])
  const { data: documentsData, isLoading: documentsDataLoading } = useQuery(
    [
      {
        variables: prepareQueryFromFilters(filters),
        query: DOCUMENTS_QUERY,
      },
    ],
    {
      onError: () => {
        toast('Unexpected error occurred, try again', { type: 'error' })
      },
    }
  )

  const { refetch: getDocumentExcelData } = useQuery(
    [
      {
        variables: prepareQueryFromFilters({ ...filters, limit: 999 }),
        query: DOCUMENTS_QUERY,
      },
    ],
    {
      enabled: false,
      onError: () => {
        toast('Unexpected error occurred, try again', { type: 'error' })
      },
    }
  )

  const handleGenerateReport = async () => {
    const documents = await getDocumentExcelData()
    let documentCollection = mapDocumentsToExcelRows(documents)

    if (!xlsxLoaded) {
      const script = document.createElement('script')
      script.src = xlsxMinifiedScriptLink
      document.body.appendChild(script)
      script.onload = x => {
        generateExcelFile({ excelLoader: window.XLSX, documentCollection })
        actions.updateState({ name: 'xlsx', value: { ...window.XLSX } })
        document.body.removeChild(script)
        window.XLSX = null
      }
    } else {
      generateExcelFile({ excelLoader: xlsxLoaded, documentCollection })
    }
  }

  const onSort = v => {
    let sortingValue = ''
    if (!filters.orderBy || filters.orderBy.length === 0) {
      sortingValue = `${v.id} ASC`
    } else if (filters.orderBy[0] === `${v.id} ASC`) {
      sortingValue = `${v.id} DESC`
    }
    const newFilterValues = produce(filters, draft => {
      draft.orderBy = sortingValue ? [sortingValue] : []
      draft.page = 1
    })
    setFilters(newFilterValues)
  }

  return (
    <div className="home">
      <div className="home-header">
        <Header />
        <CentroHorizontalLogo className="home-header__logo" />
      </div>
      {dashboardChartDataLoading && <Spinner centered />}
      {dashboardChartData?.data?.data?.dashboardGraphics && (
        <HomeInformation values={dashboardChartData?.data?.data?.dashboardGraphics} />
      )}

      <div className="home-documents">
        <Form
          initialValues={filters}
          onSubmit={() => {}}
          render={({ handleSubmit, values, form: { change, reset } }) => (
            <form onSubmit={handleSubmit}>
              <HomeDocuments
                documentsDataLoading={documentsDataLoading}
                handleGenerateReport={handleGenerateReport}
                isFiltersOpen={isFiltersOpen}
                setIsFiltersOpen={setIsFiltersOpen}
                documentsFiltersData={documentsFiltersData?.data?.data?.documentFilters}
                setFilters={setFilters}
                reset={reset}
                filters={filters}
                values={values}
                change={change}
                onSort={onSort}
                documents={documentsData?.data?.data?.documents?.collection}
                metaData={documentsData?.data?.data?.documents?.metadata}
              />
              <div className="centered-copyright">
                <p>© Centro Group LLC, 2023. All Rights Reserved.</p>{' '}
              </div>
            </form>
          )}
        />
      </div>
    </div>
  )
}

export default Home
