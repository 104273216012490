import clsx from 'clsx'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import './Button.scss'

const Button = ({ children, link, className, icon, onClick, ...props }) => {
  const navigate = useNavigate()

  const handleClick = () => {
    if (onClick) onClick()
    if (link) navigate(link)
  }

  return (
    <button onClick={handleClick} className={clsx('button', className, icon && 'button--with-icon')} {...props}>
      {icon}
      {children}
    </button>
  )
}

export default Button
