import { Form, Field } from 'react-final-form'
import { useEffect } from 'react'
import './VerificationInput.scss'
import { Button } from 'components/Button'

const VerificationInput = ({ title, message, imageSrc, imageAlt, subTitle, buttonText, buttonAction }) => {
  const handleOtp = e => {
    const input = e.target
    const value = input.value

    input.value = ''
    input.value = value ? value[0] : ''

    const fieldIndex = input.dataset.index

    if (value.length > 0 && fieldIndex < 5) {
      input.nextElementSibling.focus()
    }

    if (e.key === 'Backspace' && fieldIndex > 0) {
      input.previousElementSibling.focus()
    }
  }

  const handlePaste = (e, change) => {
    const pastedValue = e.clipboardData
      ?.getData?.('text').split("")
      

    pastedValue.forEach((el, i) => {
      change(`otp${i}`, el)
        document?.querySelector(`#otp${i}`)?.focus()
    })


  }

  useEffect(() => {
    const inputs = document.querySelectorAll('.otp-field input')

    inputs.forEach((input, index) => {
      input.dataset.index = index
      input.addEventListener('keyup', handleOtp)
    })
  }, [])

  return (
    <div className="verification-input">
      <h2 className="title">{title}</h2>
      {message && <p className="message">{message}</p>}
      {imageSrc && <img src={imageSrc} alt={imageAlt} />}
      <p className="subtitle">{subTitle}</p>
      <Form
        onSubmit={value => buttonAction(Object.values(value).join(''))}
        render={({ handleSubmit, submitting, form: { change } }) => (
          <form className="form" onSubmit={handleSubmit}>
            <div className="otp-field">
              <Field name="otp0">
                {({ input }) => (
                  <input
                    {...input}
                    type="text"
                    id="otp0"
                    maxLength="1"
                    required
                    onKeyUp={handleOtp}
                    onPaste={e => handlePaste(e, change)}
                  />
                )}
              </Field>
              <Field name="otp1">
                {({ input }) => <input {...input} type="text" id="otp1" maxLength="1" required onKeyUp={handleOtp} />}
              </Field>
              <Field name="otp2">
                {({ input }) => <input {...input} type="text" id="otp2" maxLength="1" required onKeyUp={handleOtp} />}
              </Field>
              <Field name="otp3">
                {({ input }) => <input {...input} type="text" id="otp3" maxLength="1" required onKeyUp={handleOtp} />}
              </Field>
              <Field name="otp4">
                {({ input }) => <input {...input} type="text" id="otp4" maxLength="1" required onKeyUp={handleOtp} />}
              </Field>
              <Field name="otp5">
                {({ input }) => <input {...input} type="text" id="otp5" maxLength="1" required onKeyUp={handleOtp} />}
              </Field>
            </div>
            <Button type="submit" disabled={submitting}>
              {buttonText}
            </Button>
          </form>
        )}
      />
    </div>
  )
}

export default VerificationInput
