import clsx from 'clsx'
import React from 'react'
import './ContractorDocumentsStatus.scss'

const ContractorDocumentsStatus = ({ type, value }) => {
  const isExpiring = type.includes('expiring')
  const typeString = isExpiring ? 'Expiring' : type.charAt(0).toUpperCase() + type.slice(1)
  return (
    <div
      className={clsx(
        'contractor-document-status',
        type === 'expired' && 'contractor-document-status--expired',
        isExpiring && 'contractor-document-status--expiring'
      )}
    >
      {typeString}
      <div className="contractor-document-status__count">
        <p>{value}</p>
      </div>
    </div>
  )
}

export default ContractorDocumentsStatus
