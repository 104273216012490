import { useSpring, animated as a } from '@react-spring/web'
import React from 'react'

const paths = [
  'M 19.61 23.32 C 20.65 24.36 22.34 24.36 23.38 23.32 S 24.42 20.59 23.38 19.55 L 4.62 0.78 C 3.58 -0.26 1.89 -0.26 0.85 0.78 S -0.19 3.51 0.85 4.55',
  'M 0.839 4.557 C 2.056 5.691 3.66 5.691 4.738 4.613 S 5.706 1.848 4.683 0.797 L 4.62 0.78 C 3.58 -0.26 1.89 -0.26 0.85 0.78 S -0.19 3.51 0.85 4.55',
  'M 0.78 19.51 C -0.26 20.55 -0.26 22.24 0.78 23.28 S 3.51 24.32 4.55 23.28 L 23.28 4.55 C 24.32 3.51 24.32 1.82 23.28 0.78 S 20.55 -0.26 19.51 0.78',
  'M 19.455 0.822 C 18.434 1.678 18.379 3.444 19.317 4.438 S 22.326 5.515 23.265 4.549 L 23.28 4.55 C 24.32 3.51 24.32 1.82 23.28 0.78 S 20.55 -0.26 19.51 0.78',
]

const MenuButton = ({ isOpen }) => {
  const { fill, opacity, pathA, pathB } = useSpring({
    opacity: !isOpen ? 1 : 0,
    fill: '#7f94ff',
    pathA: isOpen ? paths[0] : paths[1],
    pathB: isOpen ? paths[2] : paths[3],
    config: {
      tension: 300,
      friction: 25,
      mass: 1,
    },
  })
  const style = { fill, opacity }
  return (
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.16 24.1" width={'10rem'}>
      <a.path style={{ fill }} d={pathA} />
      <a.path
        style={style}
        d="M12.06 0C10.59 0 9.4 1.19 9.4 2.67s1.19 2.67 2.67 2.67 2.67-1.19 2.67-2.67C14.73 1.2 13.54 0 12.07 0Z"
      />
      <a.path style={{ fill }} d={pathB} />
      <a.path
        style={style}
        d="M2.73 9.33C1.26 9.33.06 10.52.06 12c0 1.47 1.19 2.67 2.67 2.67 1.47 0 2.67-1.19 2.67-2.67 0-1.47-1.19-2.67-2.66-2.67Z"
      />
      <a.path
        style={style}
        d="M12.06 9.33c-1.47 0-2.67 1.19-2.67 2.67s1.19 2.67 2.67 2.67 2.67-1.19 2.67-2.67c0-1.47-1.19-2.67-2.66-2.67Z"
      />
      <a.path
        style={style}
        d="M21.4 9.33c-1.47 0-2.67 1.19-2.67 2.67s1.19 2.67 2.67 2.67 2.67-1.19 2.67-2.67c0-1.47-1.19-2.67-2.66-2.67Z"
      />
      <a.path
        style={style}
        d="M2.73 18.67c-1.47 0-2.67 1.19-2.67 2.67s1.19 2.67 2.67 2.67 2.67-1.19 2.67-2.67c0-1.47-1.19-2.67-2.66-2.67Z"
      />
      <a.path
        style={style}
        d="M12.06 18.67c-1.47 0-2.67 1.19-2.67 2.67s1.19 2.67 2.67 2.67 2.67-1.19 2.67-2.67c0-1.47-1.19-2.67-2.66-2.67Z"
      />
      <a.path
        style={style}
        d="M21.4 18.67c-1.47 0-2.67 1.19-2.67 2.67s1.19 2.67 2.67 2.67 2.67-1.19 2.67-2.67c0-1.47-1.19-2.67-2.67-2.67Z"
      />
    </svg>
  )
}

export default MenuButton
