import { DocShowIcon } from 'assets/icons'

export const columns = [
  {
    accessorKey: 'createdAt',
    header: 'File Uploaded',
    accessorFn: row => `${row.createdAt}`,
  },
  {
    accessorKey: 'name',
    header: 'Document Name',
  },
  {
    accessorKey: 'startDate',
    header: 'Date Range',
    accessorFn: row => `${row.startDate} - ${row.endDate}`,
  },
  {
    accessorKey: 'url',
    id: 'url',
    header: '',
    cell: cell => (
      <div className="column-icon-container">
        <button className="column-icon" onClick={() => window.open(cell.getValue(), '_blank')}>
          <DocShowIcon  />
          <span>View</span>{' '}
        </button>
      </div>
    ),
  },
]
