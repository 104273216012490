import { toast } from 'react-toastify'
import startCase from 'lodash.startcase'
import { orderDataByArrayOfKeys } from 'utility/helpers'

const columnsOrder = [
  'companyName',
  'market',
  'documentType',
  'status',
  'contactName',
  'contactMobilePhone',
  'contactEmail',
  'id',
  'url',
]

export const setExcelColumnsWidth = (rows, WB) => {
  const columnsWidth = [
    { wch: 20 },
    { wch: 20 },
    { wch: 40 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 40 },
    { wch: 10 },
    { wch: 200 },
  ]
  WB['!cols'] = columnsWidth
}

export const makeHeadersTitleCased = (excelLoader, membersWB) => {
  const range = excelLoader?.utils.decode_range(membersWB['!ref'])
  for (let i = range.s.c; i <= range.e.c; ++i) {
    const address = excelLoader.utils.encode_col(i) + '1'
    if (membersWB[address]) {
      membersWB[address].v = startCase(membersWB?.[address]?.v)
    }
  }
}

export const mapDocumentsToExcelRows = documents => {
  let documentCollection = documents?.data?.data?.data?.documents?.collection?.map(el => {
    const preparedDocumentObject = {
      ...el,
      ...el.client,
      market: el?.client?.markets?.[0]?.name,
      documentType: el?.documentType?.name,
      url: el.url,
    }

    delete preparedDocumentObject?.markets
    delete preparedDocumentObject?.client
    return preparedDocumentObject
  })

  documentCollection = orderDataByArrayOfKeys(documentCollection, columnsOrder)
  return documentCollection
}

export const generateExcelFile = async ({ excelLoader, documentCollection }) => {
  const documentsWB = excelLoader?.utils.json_to_sheet(documentCollection)
  const wb = excelLoader?.utils.book_new()
  makeHeadersTitleCased(excelLoader, documentsWB)
  setExcelColumnsWidth(documentCollection, documentsWB)
  excelLoader?.utils.book_append_sheet(wb, documentsWB, 'documents')
  excelLoader?.writeFile(wb, `Documents ${new Date()}.xlsx`)
  toast('Your export has been downloaded.', { type: 'success' })
}
