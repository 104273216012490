import { keys } from 'context'

// This is an immer reducer, it's slightly different than the normal react useReducer.
// It uses an immer draft to modify the state, and then returns the new state.
// https://immerjs.github.io/immer/example-setstate#useimmerreducer
export const reducer = (draft, { type, payload }) => {
  switch (type) {
    case keys.SET_STATE:
      return void (draft[payload.name] = payload.value)
    case keys.SET_PASS_OK:
      localStorage.setItem('passOk', true)
      return void (draft.passOk = true)
    case keys.SET_PASS_NOT_OK:
      localStorage.removeItem('passOk')
      return void (draft.passOk = false)
    case keys.SET_AUTHENTICATED:
      return void (draft.user = payload)
    case keys.LOG_OUT:
      localStorage.removeItem('_centro_auth')
      draft.isLoggedIn = false
      draft.loading = false
      draft.user = null
      return void (draft.loginError = null)
    default:
      return draft
  }
}
