import Filters from 'components/Filters/Filters'
import React, { useEffect } from 'react'
import { DownloadFileIcon, FiltersIcon } from 'assets/icons'
import { FilterBadge } from 'components/FilterBadge'
import { SearchInput } from 'components/SearchInput'
import { Table } from 'components/Table'
import { PagesInfo } from 'pages/PagesInfo'
import { Pagination } from 'components/Pagination'
import { columns } from '../../columns'
import { Spinner } from 'components/Spinner'
import { Button } from 'components'

const HomeDocuments = ({
  change,
  values,
  documents,
  documentsDataLoading,
  setFilters,
  filters,
  isFiltersOpen,
  setIsFiltersOpen,
  metaData,
  reset,
  documentsFiltersData,
  handleGenerateReport,
  onSort,
}) => {
  useEffect(() => {
    setFilters(values)
  }, [values])

  return (
    <div className="home-table">
      <div className="home-table-filters">
        <div className="home-table-filters__content">
          <FiltersIcon onClick={() => setIsFiltersOpen(true)} className="home-table-filters__icon" />
          <Filters
            documentsFiltersData={documentsFiltersData}
            isOpen={isFiltersOpen}
            onClose={() => setIsFiltersOpen(false)}
            filtersArray={['company', 'markets', 'documentType', 'documentStatus', 'contact', 'createdAt']}
          />
          <span className="home-table-filters__text">Filter</span>
          {values &&
            Object.keys(values).map((el, i) => {
              if (el === 'documentTypeId') {
                const value = documentsFiltersData?.documentType?.filter(x => x?.id === values?.[el])?.[0]?.name
                return <FilterBadge onDelete={() => change(el, undefined)} key={el + i} fieldName={el} value={value} />
              } else {
                return (
                  <FilterBadge onDelete={() => change(el, undefined)} key={el + i} fieldName={el} value={values[el]} />
                )
              }
            })}

          {Object.keys(values).length > 0 && (
            <button
              onClick={() => {
                setFilters({})
                reset()
              }}
              type="button"
              className="home-table-filters__delete-button"
            >
              Clear All filters
            </button>
          )}
        </div>
        <div className="home-table-filters__report-btn-wrapper">
          <Button icon={<DownloadFileIcon />} className="home-table-filters__report-btn" onClick={handleGenerateReport}>
            Generate Report
          </Button>
        </div>
      </div>
      <div className="glass home-table__content">
        <div className="home-table-table-header">
          <h4 className="home-table-table-header__title">Expiring Documentation</h4>
          <SearchInput name="text" />
        </div>
        {documentsDataLoading ? (
          <div className="home-table__spinner">
            <Spinner centered />
          </div>
        ) : (
          <>
            <Table
              data={documents?.length > 0 ? documents : []}
              columns={columns}
              sortable
              onSort={onSort}
              orderBy={filters?.orderBy}
            />
            <div className="home-table__pagination">
              <PagesInfo
                currentPage={metaData?.currentPage}
                recordsPerPage={metaData?.limitValue}
                totalRecords={metaData?.totalCount}
                type="documents"
              />
              <Pagination
                change={change}
                totalRecords={metaData?.totalCount}
                totalPages={metaData?.totalPages}
                currentPage={metaData?.currentPage}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default HomeDocuments
