import { createContext } from 'react'

import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useImmerReducer } from 'use-immer'

import { graphql } from 'graphql/helpers'
import { initialState, keys } from './constants'
import { reducer } from './reducer'

export const Context = createContext(initialState)

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: process.env.NODE_ENV !== 'development',
      queryFn: async ({ queryKey: [{ query, variables, axiosOptions }] }) =>
        await graphql(query, variables, axiosOptions),
    },
  },
})

export const ContextProvider = ({ children }) => {
  const [state, dispatch] = useImmerReducer(reducer, initialState)

  const actions = {
    updateState(newState) {
      dispatch({ type: keys.SET_STATE, payload: newState })
    },
    setPassOk() {
      dispatch({ type: keys.SET_PASS_OK })
    },
    setPassNotOk() {
      dispatch({ type: keys.SET_PASS_NOT_OK })
    },
    setAuthenticated(payload) {
      dispatch({ type: keys.SET_AUTHENTICATED, payload })
    },
    logout: () => dispatch({ type: keys.LOG_OUT }),
  }

  return (
    <QueryClientProvider client={client}>
      <Context.Provider value={{ ...state, actions, dispatch }}>
        <BrowserRouter>{children}</BrowserRouter>
      </Context.Provider>
    </QueryClientProvider>
  )
}
