import { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { setRootFontSize } from 'utility/helpers'
import { useAcceptPaymentRedirect, useAppContext } from 'utility/hooks'
import { PrivateRoute } from 'utility/routing'

import {
  Account,
  AccountVerification,
  Contractor,
  Contractors,
  Dashboard,
  EmailVerification,
  Login,
  PhoneVerification,
  QrVerification,
  Settlements,
  Signup,
} from 'pages'
import { AddEditContractor } from 'pages/Contractors/parts/AddEditContractor'
import { Home } from 'pages/Home'
import { PasswordRoute } from 'pages/PasswordRoute/PasswordRoute'
import { ResetPassword } from 'pages/ResetPassword'

import './App.scss'

function App() {
  const { actions } = useAppContext()

  useEffect(() => {
    setRootFontSize()
  }, [])

  useAcceptPaymentRedirect(actions.setPassOk)

  return (
    <div className="App">
      <Routes>
        <Route element={<PasswordRoute />}>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/account-verification" element={<AccountVerification />} />
          <Route path="/phone-verification" element={<PhoneVerification />} />
          <Route path="/email-verification" element={<EmailVerification />} />
          <Route path="/qr-verification" element={<QrVerification />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<Dashboard />}>
              <Route path="/" element={<Home />} />
              <Route path="/contractors" element={<Contractors />} />
              <Route path="/contractor-add-edit" element={<AddEditContractor />} />
              <Route path="/contractor-add-edit/:id" element={<AddEditContractor />} />
              <Route path="/contractors/:id" element={<Contractor />} />
              <Route path="/settlements" element={<Settlements />} />
              <Route path="/account" element={<Account />} />
            </Route>
          </Route>
          <Route path="*" element={<h1>404</h1>} />
        </Route>
      </Routes>
    </div>
  )
}

export default App
