import { DocShowIcon, FindUserIcon } from 'assets/icons'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

export const columns = [
  {
    accessorFn: row => row?.client?.companyName,
    header: 'Company Name',
    id: 'company_name',
    cell: cell => <b>{cell.getValue()}</b>,
  },
  {
    accessorFn: row => row?.client?.markets[0]?.name,
    header: 'Market Type',
    id: 'market_name',
  },
  {
    accessorFn: row => row?.documentType?.name,
    header: 'Document Type',
    id: 'document_type_name',
  },
  {
    accessorFn: row => row.status,
    header: 'Status',
    id: 'status',
    cell: cell => (
      <div
        className={clsx(
          'home-table__expiring',
          cell.getValue().includes('30') && 'home-table__expiring--30',
          cell.getValue().includes('60') && 'home-table__expiring--60',
          cell.getValue().includes('90') && 'home-table__expiring--90',
          cell.getValue().includes('Current') && 'home-table__expiring--current'
        )}
      >
        {cell.getValue()}
      </div>
    ),
  },
  {
    accessorFn: row => row?.client?.contactName,
    header: 'Contact Name',
    id: 'contact_name',
  },
  {
    accessorFn: row => row?.client?.contactMobilePhone,
    header: 'Contact Phone',
    id: 'contact_mobile_phone',
  },
  {
    accessorFn: row => row?.client?.contactEmail,
    header: 'Contact Email',
    id: 'contact_email',
  },
  {
    accessorFn: row => row?.client?.id,
    header: 'Client ID',
    id: 'client_id',
  },

  {
    accessorFn: row => row?.motorCarrier,
    header: 'Motor Carrier#',
    id: 'motor_carrier',
  },
  {
    accessorFn: row => row?.usdot,
    header: 'USDOT#',
    id: 'usdot',
  },
  {
    accessorFn: row => row?.dateUploaded,
    header: 'Upload Date',
    id: 'date_uploaded',
  },
  {
    accessorFn: row => ({ url: row?.url, contactId: row?.client?.id }),
    id: 'url',
    header: '',
    cell: cell => (
      <div className="column-icon-container">
        <button className="column-icon" onClick={() => window.open(cell.getValue().url, '_blank')} >
          <DocShowIcon />
          <span>
            View
            <br /> Document
          </span>
        </button>
        {cell.getValue().contactId ? (
          <Link to={'/contractors/' + cell.getValue().contactId}>
            {' '}
            <div className="column-icon">
              <FindUserIcon />
              <span>
                View <br /> Contractor
              </span>
            </div>
          </Link>
        ) : (
          <div className="column-icon"></div>
        )}
      </div>
    ),
  },
]
