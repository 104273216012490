import clsx from 'clsx'
import React from 'react'

import './Spinner.scss'

const Spinner = ({ centered }) => {
  return (
    <div className={clsx(centered && 'spinner-wrapper')}>
      <div className="spinner">
        <div className="spinner__item"></div>
        <div className="spinner__item"></div>
        <div className="spinner__item"></div>
        <div className="spinner__item"></div>
        <div className="spinner__item"></div>
        <div className="spinner__item"></div>
        <div className="spinner__item"></div>
        <div className="spinner__item"></div>
        <div className="spinner__item"></div>
        <div className="spinner__item"></div>
        <div className="spinner__item"></div>
        <div className="spinner__item"></div>
      </div>
    </div>
  )
}
export default Spinner
