import { useNavigate } from 'react-router-dom'
import { SIGNUP } from 'graphql'
import { Form } from 'react-final-form'
import { Button } from 'components/Button'
import { Input } from 'components'
import { useMutation } from '@tanstack/react-query'

import './Signup.scss'
import { useProgressiveBackground } from 'utility/hooks'
import { toast } from 'react-toastify'
import { XIcon } from 'assets/icons'

const Signup = () => {
  const navigate = useNavigate()
  const { style } = useProgressiveBackground()

  const { mutate: createAccount } = useMutation(SIGNUP, {
    onSuccess: data => {
      navigate('/account-verification', {
        state: {
          id: data.data.id,
          email: data.data.email,
          phone: data.data.mobile_phone,
          isResetPassowrd: false,
        },
      })
    },
    onError: error => {
      if (error.response.data.email) {
        toast('Signup failed, email ' + error.response.data.email[0], { type: 'error' })
      } else {
        toast('Signup failed', { type: 'error' })
      }
    },
  })

  const onClose = () => {
    navigate('/login')
  }

  return (
    <div className="authentication" style={style}>
      <div className="container">
        <div className="signup-area">
          <div className="signup-area--background">
            <Form
              onSubmit={values =>
                createAccount({
                  ...values,
                  company_phone: values?.company_phone?.replaceAll('-', ''),
                  mobile_phone: values?.mobile_phone?.replaceAll('-', ''),
                })
              }
              render={({ handleSubmit, submitting }) => (
                <form className="form" onSubmit={handleSubmit}>
                  <XIcon className="form__close-button" onClick={onClose} />
                  <h2 className="form__title">Create Account</h2>
                  <div className="credentials">
                    <div className="credentials__left">
                      <div className="credentials__personal">
                        <div className="credentials__field">
                          <Input name="first_name" label="First Name" type="text" required />
                        </div>
                        <div className="credentials__field">
                          <Input name="last_name" label="Last Name" type="text" required />
                        </div>
                        <div className="credentials__field">
                          <Input name="mobile_phone" label="Mobile Phone" type="tel" maxLenght={12} required />
                        </div>
                      </div>
                      <div className="credentials__company">
                        <div className="credentials__field">
                          <Input name="company_name" label="Company Name" type="text" required />
                        </div>
                        <div className="credentials__field">
                          <Input name="email" label="Contact Email" type="text" required />
                        </div>
                        <div className="credentials__field">
                          <Input name="company_phone" label="Business Phone" type="tel" maxLenght={12} required />
                        </div>
                        <div className="credentials__field">
                          <Input
                            name="company_phone_ext"
                            label="Business Phone Ext"
                            type="number"
                            maxLenght={4}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="credentials__right">
                      <div className="credentials__password">
                        <div className="credentials__field credentials__field--password">
                          <Input
                            autoComplete="new-password"
                            name="password"
                            label="Create Password"
                            type="password"
                            required
                          />
                        </div>
                        <div className="credentials__field">
                          <Input name="password_confirmation" label="Retype Password" type="password" required />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="credentials__button-container">
                    <Button type="submit" disabled={submitting}>
                      Verify Account
                    </Button>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Signup
