import React, { useRef } from 'react'

import './Table.scss'
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { SortingArrowsASCIcon, SortingArrowsDESCIcon, SortingArrowsIcon } from 'assets/icons'
import { useDraggable } from 'react-use-draggable-scroll'

const Table = ({ data, columns, sortable, onSort, orderBy }) => {
  const tabRef = useRef()
  const { events } = useDraggable(tabRef)

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  const renderSortingArrows = (header, orderBy) => {
    if (!orderBy || orderBy.length === 0) {
      return <SortingArrowsIcon onClick={() => onSort(header)} className="table__sorting-arrows" />
    } else if (orderBy[0].includes('ASC') && orderBy[0].includes(header.id)) {
      return <SortingArrowsASCIcon onClick={() => onSort(header)} className="table__sorting-arrows" />
    } else if (orderBy[0].includes('DESC') && orderBy[0].includes(header.id)) {
      return <SortingArrowsDESCIcon onClick={() => onSort(header)} className="table__sorting-arrows" />
    } else {
      return <SortingArrowsIcon onClick={() => onSort(header)} className="table__sorting-arrows" />
    }
  }

  return (
    <div className="table-relative-wrapper">
      <div {...events} ref={tabRef} className="table-wrapper">
        <table className="table">
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {sortable && header.id !== 'url' && renderSortingArrows(header, orderBy)}
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            {table.getFooterGroups().map(footerGroup => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map(header => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
      </div>
    </div>
  )
}

export default Table
