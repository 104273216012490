import { Button } from 'components/Button'
import { Modal } from 'components/Modal'
import React from 'react'

const AcceptPaymentModal = ({
  isAcceptPaymentModalOpen,
  setIsAcceptPaymentModalOpen,
  handleAcceptPayment,
  amountString,
}) => {
  return (
    <Modal isOpen={isAcceptPaymentModalOpen} onClose={() => setIsAcceptPaymentModalOpen(false)}>
      <div className="modal-content modal-content-accept-payment">
        <p className="modal-content-accept-payment__question">Is this amount correct?</p>
        <h3 className="modal-content-accept-payment__name">{amountString}</h3>
        <div className="modal-content__buttons">
          <Button className="modal-content__button" onClick={handleAcceptPayment}>
            Yes and pay
          </Button>
          <Button className="modal-content__button" onClick={() => setIsAcceptPaymentModalOpen(false)}>
            No go back
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default AcceptPaymentModal
