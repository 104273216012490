import React, { useEffect } from 'react'
import { FiltersIcon } from 'assets/icons'
import { Pagination } from 'components/Pagination'
import { FilterBadge } from 'components/FilterBadge'
import Filters from 'components/Filters/Filters'
import { Table } from 'components/Table'
import { columns } from '../../columns'
import { PagesInfo } from 'pages/PagesInfo'
import { SearchInput } from 'components/SearchInput'
import { Spinner } from 'components/Spinner'

const SettlementsDocuments = ({
  filters,
  setIsFiltersOpen,
  settlementsFilterData,
  settlementsData,
  settlementsDataLoading,
  setFilters,
  onSort,
  isFiltersOpen,
  change,
  filtersLength,
  reset,
  values,
}) => {
  useEffect(() => {
    setFilters(values)
  }, [values])

  return (
    <>
      <div className="settlements-filters">
        <FiltersIcon onClick={() => setIsFiltersOpen(true)} className="settlements-filters__icon" />
        <Filters
          settlementsFilterData={settlementsFilterData?.data?.data?.spreadsheetFilters}
          isOpen={isFiltersOpen}
          onClose={() => setIsFiltersOpen(false)}
          filtersArray={['dateCreated', 'documentName', 'dateRange']}
        />
        <span className="settlements-filters__text">Filter</span>
        {values &&
          Object.keys(values).map((el, i) => {
            return <FilterBadge onDelete={() => change(el, undefined)} key={el + i} fieldName={el} value={values[el]} />
          })}

        {filtersLength > 0 && (
          <button
            onClick={() => {
              setFilters({})
              reset()
            }}
            type="button"
            className="settlements-filters__delete-button"
          >
            Clear All filters
          </button>
        )}
      </div>
      <div className="glass settlements__content">
        <div className="settlements-table-header">
          <h4 className="settlements-table-header__title">Settlements List</h4>
          <SearchInput name="text" />
        </div>
        {settlementsDataLoading ? (
          <div className="settlements__spinner">
            <Spinner centered />
          </div>
        ) : (
          <>
            {' '}
            <Table
              data={settlementsData?.collection || []}
              columns={columns}
              sortable
              onSort={onSort}
              orderBy={filters?.orderBy}
            />
            <div className="settlements__pagination">
              <PagesInfo
                currentPage={settlementsData?.metadata?.currentPage}
                recordsPerPage={settlementsData?.metadata?.limitValue}
                totalRecords={settlementsData?.metadata?.totalCount}
                type="settlements"
              />
              <Pagination
                change={change}
                totalRecords={settlementsData?.metadata?.totalCount}
                totalPages={settlementsData?.metadata?.totalPages}
                currentPage={settlementsData?.metadata?.currentPage}
              />
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default SettlementsDocuments
