import React, { useEffect, useState } from 'react'

import { animated, useSpring } from '@react-spring/web'

import './RectangleCheckbox.scss'
import { Field, useField } from 'react-final-form'

const setInputBackground = label => {
  switch (label) {
    case 'Current':
      return {
        border: "solid 0.1rem '#515bdf",
        backgroundColor: '#aeb2e6',
      }
    case 'Expired':
      return {
        border: "solid 0.1rem '#a0282d'",
        backgroundColor: '#e6aeb5',
      }
    case 'Expiring (30 Days)':
      return {
        border: "solid 0.1rem '#ab5a2e'",
        backgroundColor: '#ffbb76',
      }
    case 'Expiring (60 Days)':
      return {
        border: "solid 0.1rem '#b29139'",
        backgroundColor: '#fbe8b0',
      }
    case 'Expiring (90 Days)':
      return {
        border: "solid 0.1rem '#51a27a'",
        backgroundColor: '#ade3cc',
      }
  }
}

const RectangleCheckbox = ({ name, label, disabled, ...props }) => {
  const [isChecked, setIsChecked] = useState(false)
  const { background, border, color } = useSpring({
    background: !isChecked ? 'transparent' : setInputBackground(label).backgroundColor,
    border: !isChecked ? '0.1rem solid #ffffff' : setInputBackground(label).border,
    color: !isChecked ? '#ffffff' : '#000000',
  })

  const field = useField(name)

  useEffect(() => {
    field?.input.onChange(field?.input?.value)
    setIsChecked(field?.input?.value)
  }, [field?.input?.value])

  const handleChange = (e, input) => {
    input.onChange(e)
    setIsChecked(e.target.checked)
  }

  return (
    <Field type="checkbox" name={name}>
      {({ input }) => {
        return (
          <div className="rectangle-checkbox">
            <animated.label
              style={{ backgroundColor: background, border: border, color: color }}
              htmlFor={name}
              className={'rectangle-checkbox__label'}
            >
              {label}
            </animated.label>
            <input
              className="rectangle-checkbox__input"
              {...input}
              {...props}
              name={name}
              id={name}
              type={'checkbox'}
              onChange={e => handleChange(e, input)}
              disabled={disabled}
            />
          </div>
        )
      }}
    </Field>
  )
}

export default RectangleCheckbox
