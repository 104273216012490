import React from 'react'
import { Button } from 'components/Button'
import { Modal } from 'components/Modal'
import { Checkbox } from 'components/Checkbox'

export const EditDocumentTypesModal = props => {
  return (
    <Modal
      className="modal-edit-document-types"
      isOpen={props.isDeleteModalOpen}
      onClose={() => props.setIsDeleteModalOpen(false)}
    >
      <div className="modal-content modal-content-edit-document-types">
        <h2 className="modal-content-edit-document-types__name">Delete Document Types</h2>
        <div className="modal-content-edit-document-types__checkboxes-container">
          <div className="modal-content-edit-document-types__checkboxes">
            {' '}
            <h3>CARRIER LEVEL DOCUMENT</h3>
            {props.documentTypeCategories?.[0]?.documentTypesToDelete?.length === 0
              ? <p className='modal-content-edit-document-types__no-docs'>No document types</p>
              : props.documentTypeCategories?.[0]?.documentTypesToDelete?.map((el, i) => (
                  <Checkbox key={i} name={`docsToDelete[${el?.id}]`} label={el?.name} />
                ))}
          </div>
          <div className="modal-content-edit-document-types__checkboxes">
            {' '}
            <h3>OWNER/WORKER LEVEL DOCUMENT</h3>
            {props.documentTypeCategories?.[1]?.documentTypesToDelete?.length === 0
              ? <p className='modal-content-edit-document-types__no-docs'>No document types</p>
              : props.documentTypeCategories?.[1]?.documentTypesToDelete?.map((el, i) => (
                  <Checkbox key={i} name={`docsToDelete[${el?.id}]`} label={el?.name} />
                ))}
          </div>
        </div>
        <div className="modal-content__buttons modal-content-edit-document-types__buttons">
          <Button className="modal-content__button" onClick={() => props.handleRemoveDocumentType(props.values)}>
            Yes
          </Button>
          <Button className="modal-content__button" onClick={() => props.setIsDeleteModalOpen(false)}>
            No
          </Button>
        </div>
      </div>
    </Modal>
  )
}
