import React from 'react'
import './DashboardPieLegend.scss'

const DashboardPieLegend = ({ data, colors }) => {
  return (
    <div className="dashboard-pie-legend">
      {data?.map((el, index) => {
        return (
          <div className="dashboard-pie-legend__element" key={index}>
            <div className="dashboard-pie-legend__description">
              <div style={{ background: colors[index] }} className="dashboard-pie-legend__color-circle"></div>
              {el.name}
            </div>
            <span className="dashboard-pie-legend__value">{el.value}%</span>
          </div>
        )
      })}
    </div>
  )
}

export default DashboardPieLegend
