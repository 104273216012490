import React from 'react'
import Lottie from 'react-lottie'
import imageUpload from 'assets/lottie/imageUpload'
import './UploadFileLoader.scss'

const UploadFileLoader = ({ callbackFn }) => {
  return (
    <Lottie
      className="upload-loader"
      options={{
        loop: false,
        autoplay: true,
        animationData: imageUpload,
      }}
      eventListeners={[
        {
          eventName: 'complete',
          callback: () => callbackFn(),
        },
      ]}
    />
  )
}

export default UploadFileLoader
