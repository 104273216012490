import React, { useState } from 'react'
import { createPortal } from 'react-dom'
import { XIcon } from 'assets/icons'
import { Input } from 'components/Input'
import './Filters.scss'
import { Select } from 'components/Select'
import { FilterItem } from 'components/FilterItem'
import { Radio } from 'components/Radio'
import { RectangleCheckbox } from 'components/RectangleCheckbox'

const Filters = ({ isOpen, onClose, filtersArray = [], documentsFiltersData, type }) => {
  const [openFiltersArray, setOpenFiltersArray] = useState([])

  const handleChangeOpenFilters = name => {
    if (openFiltersArray.includes(name)) {
      setOpenFiltersArray(openFiltersArray.filter(el => el !== name))
    } else {
      setOpenFiltersArray([...openFiltersArray, name])
    }
  }

  return isOpen
    ? createPortal(
        <div className="filters">
          <div className="filters__header">
            <XIcon className="filters__close-button" onClick={onClose} />
            <div className="filters__title">Filter By</div>
          </div>

          {filtersArray?.map((el, i) => {
            return (
              <FilterItem
                key={i}
                handleChangeOpenFilters={handleChangeOpenFilters}
                variant={el}
                openFiltersArray={openFiltersArray}
              >
                {el === 'dateCreated' && <Input name="dateCreated" label="Date" type="date" />}
                {el === 'documentName' && (
                  <Select
                    options={documentsFiltersData?.name || documentsFiltersData?.documentName}
                    name="documentName"
                    label="Document Name"
                  />
                )}
                {el === 'dateRange' && (
                  <>
                    <Input name="dateRangeFrom" label="From Date" type="date" />
                    <Input name="dateRangeTo" label="To Date" type="date" />
                  </>
                )}
                {el === 'company' && (
                  <>
                    <Select options={documentsFiltersData?.companyName} name="companyName" label="Company Name" />
                    <Select options={documentsFiltersData?.clientId} name="clientId" label="Client ID" />
                    <Select options={documentsFiltersData?.motorCarrier} name="motorCarrier" label="Motor Carrier #" />
                    <Select options={documentsFiltersData?.usdot} name="usdot" label="USDOT#" />
                  </>
                )}
                {el === 'markets' && (
                  <>
                    {documentsFiltersData?.market?.length === 0 && <span>No markets type filters</span>}
                    {documentsFiltersData?.market?.map((el, i) => {
                      return <Radio key={i} name="markets" label={el.name} value={el.name} />
                    })}
                  </>
                )}
                {el === 'documentType' && type !== 'contractorDocuments' && (
                  <>
                    {documentsFiltersData?.documentType?.length === 0 && <span>No document type filters</span>}
                    {documentsFiltersData?.documentType?.map((el, i) => {
                      return <Radio key={i} name="documentTypeId" label={el.name} value={el.id} />
                    })}
                  </>
                )}
                {el === 'documentType' && type === 'contractorDocuments' && (
                  <>
                    {documentsFiltersData?.documentType?.length === 0 && <span>No document type filters</span>}
                    {documentsFiltersData?.documentType?.map((el, i) => {
                      return <Radio key={i} name="documentType" label={el} value={el} />
                    })}
                  </>
                )}
                {el === 'documentStatus' && (
                  <div className="filters-filter__document-statuses">
                    <RectangleCheckbox name="expiring.days30" label="Expiring (30 Days)" />
                    <RectangleCheckbox name="expiring.days60" label="Expiring (60 Days)" />
                    <RectangleCheckbox name="expiring.days90" label="Expiring (90 Days)" />
                    <RectangleCheckbox name="expiring.current" label="Current" />
                    <RectangleCheckbox name="expiring.expired" label="Expired" />
                  </div>
                )}
                {el === 'contact' && (
                  <>
                    <Select options={documentsFiltersData?.contactName} name="contactName" label="Contact Name" />
                    <Select
                      options={documentsFiltersData?.contactMobilePhone}
                      name="contactMobilePhone"
                      label="Contact Mobile Number"
                    />
                    <Select options={documentsFiltersData?.contactEmail} name="contactEmail" label="Contact Email" />
                  </>
                )}
                {el === 'createdAt' && <Input name="createdAt" label="Date" type="date" />}
              </FilterItem>
            )
          })}
        </div>,
        document.getElementById('root')
      )
    : null
}

export default Filters
