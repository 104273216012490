import { useContext } from 'react'

import { Context } from 'context'

import './Header.scss'

const Header = ({ children }) => {
  const { user } = useContext(Context)

  return (
    <div className="header">
      <div className="company">
        <h4 className="company__name">{user?.company_name}</h4>
      </div>
      <div className="user">
        <h6 className="user__name">{`Welcome, ${user?.first_name} ${user?.last_name}!`}</h6>
      </div>
      {children}
    </div>
  )
}

export default Header
