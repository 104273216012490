import React from 'react'
import './PagesInfo.scss'

const setPagesRange = (currentPage, recordsPerPage, totalRecords) => {
  const maximuRecordsInCurrentPage = currentPage * recordsPerPage
  return `${(currentPage - 1) * recordsPerPage + 1} - ${
    maximuRecordsInCurrentPage > totalRecords ? totalRecords : maximuRecordsInCurrentPage
  }`
}

const PagesInfo = ({ currentPage, recordsPerPage, totalRecords, type }) => {
  return (
    <div className="pages-info">
      Showing <span className="pages-info__number">{setPagesRange(currentPage, recordsPerPage, totalRecords)}</span> of{' '}
      {totalRecords} {type}
    </div>
  )
}

export default PagesInfo
