import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { toast } from 'react-toastify'
import { useMutation, useQuery } from '@tanstack/react-query'
import { CREATE_PAYMENT, SEND_NOTIFICATION, UPLOAD_SPREADSHEET } from 'graphql'
import { xlsxMJSScriptLink } from 'utility/consts'

import { Input } from 'components'
import { Button } from 'components/Button'
import { Dropzone } from 'components/Dropzone'
import { Modal } from 'components/Modal'
import { Context } from 'context'

import { AcceptPaymentModal } from '../AcceptPaymentModal'

function errorPaymentToast(message) {
  return message
    ? toast.update('payment', {
        type: 'error',
        autoClose: false,
        toastId: 'payment',
        isLoading: false,
        render: message,
      })
    : toast.update('payment', {
        type: 'error',
        autoClose: false,
        toastId: 'payment',
        isLoading: false,
        render: 'Unexpected error occurred, try again',
        closeOnClick: true,
      })
}

function loadingPaymentToast(paymentLoading) {
  toast.isActive('payment')
    ? toast.update('payment', {
        type: 'info',
        autoClose: false,
        isLoading: paymentLoading,
        toastId: 'payment',
        closeOnClick: true,
        render: 'Loading...',
      })
    : toast('Loading...', { type: 'info', autoClose: false, toastId: 'payment', isLoading: paymentLoading })
}

const UploadSettlementsModal = ({
  setIsUploadModalOpen,
  isUploadModalOpen,
  refetchSettlements,
  refetchSettlementsFilters,
}) => {
  const [xlsxData, setXLSXData] = useState(null)
  const [isAcceptPaymentModalOpen, setIsAcceptPaymentModalOpen] = useState(false)
  const [formValues, setFormValues] = useState({})

  const { actions, xlsx } = useContext(Context)

  const { refetch: sendNotification } = useQuery(
    [
      {
        query: SEND_NOTIFICATION,
        variables: { ids: xlsxData?.ids },
      },
    ],
    {
      enabled: false,
      onSuccess: res => res?.data?.data?.sendNotification?.forEach(el => toast(el, { type: 'success' })),
    }
  )

  const { mutate: uploadSpreadsheet, isLoading } = useMutation(UPLOAD_SPREADSHEET, {
    onSuccess: res => {
      sendNotification()
      if (res) {
        setTimeout(() => {
          refetchSettlements()
          refetchSettlementsFilters()
        }, 2000)
        setIsUploadModalOpen(false)
        toast('User uploaded document', { type: 'success' })
      }
    },
    onError: () => {
      toast('Unexpected error occurred, try again', { type: 'error' })
    },
  })

  const onSuccess = useCallback(
    res => {
      if (res.data.data?.createSettlement?.stripeError?.message)
        return void errorPaymentToast(res.data.data?.createSettlement?.stripeError?.message)

      if (!res.data?.data?.createSettlement?.sessionUrl) return void errorPaymentToast()

      const stripeCheckoutUrl = res.data?.data?.createSettlement?.sessionUrl
      uploadSpreadsheet({
        name: formValues?.name,
        startDate: formValues?.startDate,
        endDate: formValues?.endDate,
        file: formValues?.file,
      })
      window.open(stripeCheckoutUrl, '_self')
    },
    [formValues?.endDate, formValues?.file, formValues?.name, formValues?.startDate, uploadSpreadsheet]
  )

  const onError = () => {
    toast.update('payment', {
      type: 'error',
      autoClose: false,
      toastId: 'payment',
      isLoading: false,
      closeOnClick: true,
      render: 'Unexpected error occurred, try again',
    })
  }

  const {
    mutate: createPayment,
    data: paymentData,
    isSuccess: paymentSuccess,
    isError: paymentError,
    isLoading: paymentLoading,
  } = useMutation(CREATE_PAYMENT)

  // Handle Create Payment Loading and Response
  if (paymentLoading) loadingPaymentToast(paymentLoading)

  useEffect(() => {
    if (paymentSuccess) onSuccess(paymentData)
  }, [onSuccess, paymentData, paymentSuccess])

  if (paymentError) onError()

  const getIdsFromXlsx = workbook => {
    const count = workbook?.Sheets?.Sheet1?.B49?.v
    let ids = []
    for (let i = 2; i < 2 + count; i++) {
      if (workbook?.Sheets?.Sheet1['A' + i]) {
        ids.push(workbook?.Sheets?.Sheet1['A' + i].v)
      }
    }
    return ids
  }

  const parseXLSX = async file => {
    let xlsxLoaded = xlsx
    if (!xlsxLoaded) {
      xlsxLoaded = await import(/*webpackIgnore: true*/ xlsxMJSScriptLink)
      actions.updateState({ name: 'xlsx', value: { ...xlsxLoaded } })
    }

    if (!file) return
    const data = await file.arrayBuffer()
    const workbook = xlsxLoaded.read(data)

    setXLSXData({
      amount: workbook?.Sheets?.Sheet1?.L49?.v,
      amountString: workbook?.Sheets?.Sheet1?.L49?.w,
      source: workbook?.Sheets?.Sheet1?.A55?.v,
      description: workbook?.Sheets?.Sheet1?.A57?.v,
      ids: getIdsFromXlsx(workbook),
    })
  }

  const redirectToAcceptModal = v => {
    const renamedFile = new File([v.document[0]], v?.name + '.xlsx', {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    setFormValues({ name: v.name, startDate: v.startDate, endDate: v.endDate, file: renamedFile })
    setIsAcceptPaymentModalOpen(true)
  }

  const handleAcceptPayment = () =>
    createPayment({
      input: {
        settlementInput: {
          amount: xlsxData?.amount,
          source: xlsxData?.source,
          description: xlsxData?.description,
        },
      },
    })

  return (
    <>
      <Modal
        className={'modal-upload-spreadsheet'}
        isOpen={isUploadModalOpen}
        onClose={() => setIsUploadModalOpen(false)}
      >
        <div className="modal-content modal-content-upload-spreadsheet">
          <p className="modal-content-upload-spreadsheet__title">Upload Settlement Spreadsheet</p>
          <Form
            onSubmit={v => redirectToAcceptModal(v)}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div className="modal-content-upload-spreadsheet__container">
                  <div className="modal-content-upload-spreadsheet__container-left">
                    <Dropzone
                      parseXLSX={parseXLSX}
                      required
                      name="document"
                      fileExtension={'.xlsx'}
                      accept={{
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                      }}
                    />
                    <Button disabled={isLoading} className="modal-content-upload-spreadsheet__button-save">
                      SAVE
                    </Button>
                  </div>
                  <div className="modal-content-upload-spreadsheet__container-right">
                    <Input name="name" label="Spreadsheet Name" type="text" required />
                    <div className="modal-content-upload-spreadsheet__date-range">
                      <Input name="startDate" label="Date range" type="date" required />
                      <Input name="endDate" label="" type="date" required withoutLabel />
                    </div>
                  </div>
                </div>
              </form>
            )}
          />
        </div>
      </Modal>
      {isAcceptPaymentModalOpen && (
        <AcceptPaymentModal
          isAcceptPaymentModalOpen={isAcceptPaymentModalOpen}
          setIsAcceptPaymentModalOpen={setIsAcceptPaymentModalOpen}
          handleAcceptPayment={handleAcceptPayment}
          amountString={xlsxData?.amountString}
        />
      )}
    </>
  )
}

export default UploadSettlementsModal
