import { api } from 'utility/api'

import { graphql, graphqlForUpload } from './helpers'

export const LOGIN = async variables => await api.post('/brokers/sessions', variables)
export const SIGNUP = async variables => await api.post('/brokers/registrations', { broker: variables })
export const SEND_EMAIL_PHONE_VERIFICATION = async variables =>
  await api.post('/brokers/confirmations/send_code', variables)
export const VERIFY_EMAIL_PHONE_CODE = async variables =>
  await api.post('/brokers/confirmations/verify_code', variables)
export const SEND_QR_VERIFICATION = async variables => await api.post('/brokers/confirmations/send_qr_code', variables)
export const VERIFY_QR_CODE = async variables => await api.post('/brokers/confirmations/verify_qr_code', variables)
export const GET_USER_DATA = async variables => await api.post('/recover_password/get_data', variables)
export const RESET_PASSWORD = async variables => await api.post('/recover_password/update_password', variables)

export const UPDATE_BROKER = async variables =>
  await graphql(
    `
      mutation ($input: UpdateBrokerInput!) {
        updateBroker(input: $input) {
          broker {
            id
            email
            firstName
            lastName
            mobilePhone
            companyName
            companyPhone
            companyPhoneExt
            emailNotification
            smsNotification
            notificationDays
          }
          errors
        }
      }
    `,
    variables,
    {
      responseType: 'json',
    }
  )
export const CREATE_CONTRACTOR = async variables =>
  await graphql(
    `
      mutation ($input: CreateContractorInput!) {
        createContractor(input: $input) {
          clientMutationId
          contractor {
            id
          }
          errors
          message
        }
      }
    `,
    variables
  )

export const UPDATE_CONTRACTOR = async variables =>
  await graphql(
    `
      mutation ($input: UpdateContractorInput!) {
        updateContractor(input: $input) {
          clientMutationId
          contractor {
            id
          }
          errors
          message
        }
      }
    `,
    variables
  )

export const DESTROY_BROKER = async variables =>
  await graphql(
    `
      mutation ($input: DestroyBrokerInput!) {
        destroyBroker(input: $input) {
          message
          errors
        }
      }
    `,
    variables,
    {
      responseType: 'json',
    }
  )

export const DESTROY_CONTRACTOR = async variables =>
  await graphql(
    `
      mutation ($input: DestroyContractorInput!) {
        destroyContractor(input: $input) {
          message
          errors
        }
      }
    `,
    variables,
    {
      responseType: 'json',
    }
  )

export const DESTROY_DOCUMENT_TYPES = async variables =>
  await graphql(
    `
      mutation ($input: DestroyDocumentTypesInput!) {
        destroyDocumentTypes(input: $input) {
          message
          error
        }
      }
    `,
    variables,
    {
      responseType: 'json',
    }
  )

export const DESTROY_MARKET = async variables =>
  await graphql(
    `
      mutation ($input: DestroyMarketInput!) {
        destroyMarket(input: $input) {
          message
          errors
        }
      }
    `,
    variables,
    {
      responseType: 'json',
    }
  )

export const DESTROY_DOCUMENT = async variables =>
  await graphql(
    `
      mutation ($input: DestroyDocumentInput!) {
        destroyDocument(input: $input) {
          clientMutationId
          errors
          message
        }
      }
    `,
    variables,
    {
      responseType: 'json',
    }
  )

export const CREATE_PAYMENT = async variables =>
  await graphql(
    `
      mutation ($input: CreateSettlementInput!) {
        createSettlement(input: $input) {
          errors
          sessionUrl
          stripeError {
            chargeId
            code
            declineCode
            message
            param
            status
            type
          }
        }
      }
    `,
    variables,
    {
      responseType: 'json',
    }
  )

export const UPLOAD_SPREADSHEET = async ({ name, startDate, endDate, file }) => {
  const formData = new FormData()

  const queryOperationsObj = {
    query: `mutation ( $file: Upload! ) {
      uploadSpreadsheet(
        input: {
          spreadsheetInput: {
            name: "${name}",
            startDate: "${startDate}",
            endDate: "${endDate}" },
          document: $file })
        { message, url, errors } } `,
    variables: { file: null },
  }
  formData.append('operations', JSON.stringify(queryOperationsObj))
  formData.append('map', JSON.stringify({ 0: ['variables.file'] }))
  formData.append('0', file)
  return graphqlForUpload(formData)
}

export const UPLOAD_DOCUMENT = async ({
  name,
  expiration,
  documentTypeId,
  clientId,
  motorCarrier,
  usdot,
  file,
  newDocument,
  documentTypeCategoryId,
}) => {
  const formData = new FormData()

  const queryOperationsObj = {
    query: `mutation ( $file: Upload! ) {
      uploadDocument(
        input: {
          documentInput: {
            ${name ? `title: "${name}",` : ''}
            ${expiration ? `expiration: "${expiration}",` : ''}
            ${documentTypeId ? `documentTypeId: ${documentTypeId},` : ''}
            ${newDocument ? `newDocument: "${newDocument}",` : ''}
            ${clientId ? `clientId: ${clientId},` : ''}
            ${motorCarrier ? `motorCarrier: "${motorCarrier}",` : ''}
            ${usdot ? `usdot: "${usdot}",` : ''}
            ${documentTypeCategoryId ? `documentTypeCategoryId: ${documentTypeCategoryId},` : ''}
           },
          document: $file })
        { message, url, errors } } `,
    variables: { file: null },
  }
  formData.append('operations', JSON.stringify(queryOperationsObj))
  formData.append('map', JSON.stringify({ 0: ['variables.file'] }))
  formData.append('0', file)
  return graphqlForUpload(formData)
}
