import { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Form } from 'react-final-form'
import { useMutation } from '@tanstack/react-query'

import { LOGIN } from 'graphql'
import { Context } from 'context'
import { useProgressiveBackground } from 'utility/hooks'
import { Input } from 'components/Input'

import './Login.scss'
import { Button } from 'components/Button'
import { Spinner } from 'components/Spinner'
import { toast } from 'react-toastify'
import { CentroHorizontalLogo } from 'assets/logos'

const Login = () => {
  const navigate = useNavigate()
  const { actions } = useContext(Context)
  const { style } = useProgressiveBackground()

  const { mutate: login, isLoading } = useMutation(LOGIN, {
    onSuccess: response => {
      localStorage.setItem('_centro_auth', JSON.stringify(response.data))
      actions.setAuthenticated(response.data)
      navigate('/')
      toast('User logged in succesfully', { type: 'success' })
    },
    onError: err => {
      toast('Incorrect email or password', { type: 'error' })
    },
  })

  if (isLoading) {
    return <Spinner centered />
  }

  return (
    <div className="login">
      <div className="container" style={style}>
        <div className="login-area">
          <div className="login-area--background" style={style}>
            <Form
              onSubmit={value => login(value)}
              render={({ handleSubmit, submitting, values }) => (
                <form className="form" onSubmit={handleSubmit}>
                  <h2 className="form__title">Welcome</h2>
                  <Input name="email" label="Email" type="email" required />
                  <Input type="password" name="password" noValidate />
                  <Button type="submit" disabled={submitting}>
                    Log In
                  </Button>
                  <div className="link-area">
                    <Link
                      to="/account-verification"
                      state={{ isResetPassword: true, email: values?.email }}
                      className="link-area__link"
                    >
                      Recover Password
                    </Link>
                    <Link to="/signup" className="link-area__link">
                      Create Account
                    </Link>
                  </div>
                  <div className="about">
                    <CentroHorizontalLogo />
                    <p>© Centro Group LLC, 2023. All Rights Reserved.</p>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
