import React, { useState } from 'react'
import { Form } from 'react-final-form'
import { useQuery } from '@tanstack/react-query'
import { SETTLEMENTS_FILTER_QUERY, SETTLEMENTS_QUERY } from 'graphql'
import produce from 'immer'
import { useDownloadXLSXTemplate } from 'utility/useDownloadTemplate'

import { Header } from 'components'
import { Button } from 'components/Button'

import { SettlementsDocuments } from './parts/SettlementsDocuments'
import { UploadSettlementsModal } from './parts/UploadSettlementsModal/'

import { DownloadFileIcon, UploadFileIcon } from 'assets/icons'
import { CentroHorizontalLogo } from 'assets/logos'
import './Settlements.scss'

const Settlements = () => {
  const [filters, setFilters] = useState({ limit: 9 })
  const [isFiltersOpen, setIsFiltersOpen] = useState(false)
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)

  const downloadTemplate = useDownloadXLSXTemplate()

  const {
    data: settlementsData,
    isLoading: settlementsDataLoading,
    refetch: refetchSettlements,
  } = useQuery([
    {
      variables: produce(filters, draft => {
        draft.createdAt = filters.dateCreated
        draft.startDate = filters.dateRangeFrom
        draft.endDate = filters.dateRangeTo
        draft.limit = 9
        delete draft.dateCreated
        delete draft.dateRangeFrom
        delete draft.dateRangeTo
      }),
      query: SETTLEMENTS_QUERY,
    },
  ])
  const { data: settlementsFilterData, refetch: refetchSettlementsFilters } = useQuery([
    {
      query: SETTLEMENTS_FILTER_QUERY,
    },
  ])

  const onSort = v => {
    let sortingValue = ''
    if (!filters.orderBy || filters.orderBy.length === 0) {
      sortingValue = `${v.id} ASC`
    } else if (filters.orderBy[0] === `${v.id} ASC`) {
      sortingValue = `${v.id} DESC`
    }
    const newFilterValues = produce(filters, draft => {
      draft.orderBy = sortingValue ? [sortingValue] : []
      draft.page = 1
    })
    setFilters(newFilterValues)
  }

  return (
    <div className="settlements">
      <div className="settlements__header">
        <div>
          <Header />
        </div>
        <div className="settlements__buttons-and-logo">
          <Button onClick={downloadTemplate} icon={<DownloadFileIcon />} className="settlements__button">
            Download spreadsheet template
          </Button>
          <Button onClick={() => setIsUploadModalOpen(true)} icon={<UploadFileIcon />} className="settlements__button">
            Upload completed spreadsheet
          </Button>
          <CentroHorizontalLogo className="settlements__logo" />
        </div>
      </div>

      <Form
        onSubmit={v => {}}
        initialValues={filters}
        render={({ handleSubmit, values, form: { change, reset } }) => (
          <form onSubmit={handleSubmit}>
            <SettlementsDocuments
              change={change}
              reset={reset}
              settlementsDataLoading={settlementsDataLoading}
              setFilters={setFilters}
              settlementsData={settlementsData?.data?.data?.spreadsheet}
              documentsFiltersData={settlementsFilterData}
              isFiltersOpen={isFiltersOpen}
              setIsFiltersOpen={setIsFiltersOpen}
              values={values}
              filtersLength={Object.keys(values).length}
              onSort={onSort}
              filters={filters}
            />
            <div className="centered-copyright">
              <p>© Centro Group LLC, 2023. All Rights Reserved.</p>{' '}
            </div>
          </form>
        )}
      />

      <UploadSettlementsModal
        isUploadModalOpen={isUploadModalOpen}
        refetchSettlements={refetchSettlements}
        refetchSettlementsFilters={refetchSettlementsFilters}
        setIsUploadModalOpen={setIsUploadModalOpen}
      />
    </div>
  )
}

export default Settlements
