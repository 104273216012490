import React from 'react'
import { Field } from 'react-final-form'
import './Checkbox.scss'

const Checkbox = ({ name, value, label, onChange }) => {
  return (
    <Field name={name} type="checkbox" value={value}>
      {({ input }) => (
        <div className="checkbox-wrapper">
          <label>
            <input
              className="checkbox"
              {...input}
              onChange={(e) => {
                onChange?.(e)
                input?.onChange?.(e)
              }}
            />
            {label}
          </label>
        </div>
      )}
    </Field>
  )
}

export default Checkbox
