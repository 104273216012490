import { useQuery } from '@tanstack/react-query'
import { AddUserIcon } from 'assets/icons'
import { CentroHorizontalLogo } from 'assets/logos'
import { Header } from 'components'
import { Button } from 'components/Button'
import { CONTRACTORS_QUERY, CONTRACTORS_FILTERS_QUERY } from 'graphql'
import produce from 'immer'
import React, { useState } from 'react'
import { Form } from 'react-final-form'
import { toast } from 'react-toastify'
import './Contractors.scss'
import { ContractorsList } from './parts/ContractorsList'

const Contractors = () => {
  const [filters, setFilters] = useState({ limit: 9 })
  const [isFiltersOpen, setIsFiltersOpen] = useState(false)

  const { data: contractorsFilterData } = useQuery([
    {
      query: CONTRACTORS_FILTERS_QUERY,
    },
  ])

  const { data: contractorsData, isLoading: contractorsDataLoading } = useQuery(
    [
      {
        variables: produce(filters, draft => {
          const notificationDays = []
          if (filters?.expiring?.current) {
            notificationDays.push('Current')
          }
          if (filters?.expiring?.expired) {
            notificationDays.push('Expired')
          }
          if (filters?.expiring?.days30) {
            notificationDays.push('Expiring (30 Days)')
          }
          if (filters?.expiring?.days60) {
            notificationDays.push('Expiring (60 Days)')
          }
          if (filters?.expiring?.days90) {
            notificationDays.push('Expiring (90 Days)')
          }
          if (filters?.expiring) {
            delete draft.expiring
          }
          draft.status = notificationDays
          draft.limit = 9
        }),
        query: CONTRACTORS_QUERY,
      },
    ],
    {
      onError: () => {
        toast('Unexpected error occurred, try again', { type: 'error' })
      },
    }
  )

  const onSort = v => {
    let sortingValue = ''
    if (!filters.orderBy || filters.orderBy.length === 0) {
      sortingValue = `${v.id} ASC`
    } else if (filters.orderBy[0] === `${v.id} ASC`) {
      sortingValue = `${v.id} DESC`
    }
    const newFilterValues = produce(filters, draft => {
      draft.orderBy = sortingValue ? [sortingValue] : []
      draft.page = 1
    })
    setFilters(newFilterValues)
  }

  return (
    <div className="contractors">
      <div className="contractors__header">
        <div>
          <Header />
        </div>
        <div className="contractors__buttons-and-logo">
          <Button icon={<AddUserIcon />} link="/contractor-add-edit" className="contractors__button">
            Add contractor
          </Button>
          <CentroHorizontalLogo className="contractors__logo" />
        </div>
      </div>
      <Form
        onSubmit={v => {}}
        initialValues={filters}
        render={({ handleSubmit, values, form: { change, reset } }) => (
          <form onSubmit={handleSubmit}>
            <ContractorsList
              change={change}
              reset={reset}
              contractorsDataLoading={contractorsDataLoading}
              setFilters={setFilters}
              contractorsData={contractorsData?.data?.data?.contractors}
              contractorsFilterData={contractorsFilterData}
              isFiltersOpen={isFiltersOpen}
              setIsFiltersOpen={setIsFiltersOpen}
              values={values}
              filtersLength={Object.keys(values).length}
              onSort={onSort}
              filters={filters}
            />
            <div className="centered-copyright">
              <p>© Centro Group LLC, 2023. All Rights Reserved.</p>{' '}
            </div>
          </form>
        )}
      />
    </div>
  )
}

export default Contractors
