import { useMutation } from '@tanstack/react-query'
import { Email, Google, Phone } from 'assets/icons'
import { Verification, VerificationItem } from 'components'
import { GET_USER_DATA } from 'graphql'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { validateEmail } from 'utility/validation'

const AccountVerification = () => {
  const { state } = useLocation()
  const navigate = useNavigate()

  const [phoneWithMask, setPhoneWithMask] = useState('')
  const [userId, setUserId] = useState(null)

  const { mutate: getUserData } = useMutation(GET_USER_DATA, {
    onSuccess: data => {
      setPhoneWithMask('****' + data.data.phone)
      setUserId(data.data.id)
    },
    onError: () => {
      toast('Unexpected error occurred, try again', { type: 'error' })
    },
  })

  useEffect(() => {
    getUserData({ email: email })

    if (validateEmail(state.email) || !state.email) {
      toast('Email is invalid', { type: 'error', toastId: 'email-account' })
      setTimeout(() => {
        navigate('/login')
      }, 1500)
    }
    if (!state) {
      navigate('/login')
    }
    if (phone) {
      setPhoneWithMask(
        phone
          .substring(0, phone.length - 4)
          .split('')
          .map(() => '*')
          .join('') + phone.slice(-4)
      )
    }
    if (id) {
      setUserId(id)
    }
  }, [])

  const { email, phone, id, isResetPassword } = state

  if (!state) {
    return
  }

  return (
    <Verification help={true} isResetPassword={isResetPassword}>
      <p className="message">
        Verifying your account with Multi-factor Authentication adds an additional layer of security to your account.
        Your account will remain verified for 90 days.
      </p>
      <h2 className="title">Choose a Verification Method</h2>
      <VerificationItem
        icon={<Phone className="icon" />}
        data={{ id: userId, phoneWithMask: phoneWithMask, isResetPassword: isResetPassword }}
        title="Phone Message"
        message={`Receive a phone message with a verification code. The code will be sent to the phone number on record: ${phoneWithMask}`}
        buttonText="Send to Phone"
        link="/phone-verification"
      />
      <VerificationItem
        icon={<Email className="icon" />}
        data={{ id: userId, email: email, isResetPassword: isResetPassword }}
        title="Email"
        message={`Receive an email with a verification code. The code will be sent to the email address on record: ${email}`}
        buttonText="Send to Email"
        link="/email-verification"
      />
      <VerificationItem
        icon={<Google className="icon" />}
        title="Google Authenticator"
        data={{ id: userId, isResetPassword: isResetPassword }}
        message="This two-factor authentication app will generate the security codes necessary to verify your account. You’ll need to have the Google Authenticator app installed on your phone and go through a quick one-time setup."
        buttonText="Setup App"
        link="/qr-verification"
      />
    </Verification>
  )
}

export default AccountVerification
