import { useMutation, useQuery } from '@tanstack/react-query'
import { DropLeftCircle, RegistrationIcon, UploadFileIcon } from 'assets/icons'
import { CentroHorizontalLogo } from 'assets/logos'
import { Header } from 'components'
import { Button } from 'components/Button'
import { DashboardPieChart } from 'components/DashBoardPieChart'
import { DashboardPieLegend } from 'components/DashboardPieLegend'
import { Modal } from 'components/Modal'
import { Spinner } from 'components/Spinner'
import {
  CONTRACTOR_FILTERS_QUERY,
  CONTRACTOR_WITH_DOCS_QUERY,
  DESTROY_CONTRACTOR,
  DOCUMENTS_FILTER_QUERY,
} from 'graphql'
import produce from 'immer'
import React, { useState } from 'react'
import { Form } from 'react-final-form'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import './Contractor.scss'
import { ContractorDocuments } from './parts/ContractorDocuments'
import { UploadContractorDocumentModal } from './parts/UploadContractorDocumentModal'

const Contractor = () => {
  const [contractor, setContractor] = useState({})
  const [isFiltersOpen, setIsFiltersOpen] = useState(false)
  const [filters, setFilters] = useState({ limit: 9 })
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const chartColors = ['#a2282d', '#b9541f', '#b9901f', '#17a577', '#5c7cfa']

  const chartData = [
    { name: 'Expired', value: contractor?.documentationStatus?.expired },
    { name: 'Expiring (30 days)', value: contractor?.documentationStatus?.expiring30Days },
    { name: 'Expiring (60 days)', value: contractor?.documentationStatus?.expiring60Days },
    { name: 'Expiring (90 days)', value: contractor?.documentationStatus?.expiring90Days },
    { name: 'Current', value: contractor?.documentationStatus?.current },
  ]
  const { id } = useParams()
  const navigate = useNavigate()

  const [isUploadDocumentModalOpen, setIsUploadDocumentModalOpen] = useState(false)

  const { data: contractorFilterData } = useQuery([
    {
      query: CONTRACTOR_FILTERS_QUERY,
      variables: { id },
    },
  ])

  const { data: documentsFiltersData } = useQuery([
    {
      query: DOCUMENTS_FILTER_QUERY,
    },
  ])

  const { isLoading: contractorDataIsLoading, refetch: refetchContractorData } = useQuery(
    [
      {
        query: CONTRACTOR_WITH_DOCS_QUERY,
        variables: produce(filters, draft => {
          const notificationDays = []
          if (filters?.expiring?.current) {
            notificationDays.push('Current')
          }
          if (filters?.expiring?.expired) {
            notificationDays.push('Expired')
          }
          if (filters?.expiring?.days30) {
            notificationDays.push('Expiring (30 Days)')
          }
          if (filters?.expiring?.days60) {
            notificationDays.push('Expiring (60 Days)')
          }
          if (filters?.expiring?.days90) {
            notificationDays.push('Expiring (90 Days)')
          }
          if (filters?.expiring) {
            delete draft.expiring
          }
          draft.id = id
          draft.status = notificationDays
        }),
      },
    ],
    {
      onSuccess: res => {
        const contractorData = res?.data?.data?.contractor
        setContractor(contractorData)
      },
      onError: res => {
        navigate('/contractors')
      },
    }
  )

  const { mutate: destroyContractor } = useMutation(DESTROY_CONTRACTOR, {
    onSuccess: () => {
      navigate('/contractors')
      toast('Contractor removed successfully', { type: 'success' })
    },
  })

  const handleDeleteAccount = () => {
    destroyContractor({ input: { destroyContractorInput: { id } } })
  }

  const onSort = v => {
    let sortingValue = ''
    if (!filters.orderBy || filters.orderBy.length === 0) {
      sortingValue = `${v.id} ASC`
    } else if (filters.orderBy[0] === `${v.id} ASC`) {
      sortingValue = `${v.id} DESC`
    }
    const newFilterValues = produce(filters, draft => {
      draft.orderBy = sortingValue ? [sortingValue] : []
      draft.page = 1
    })
    setFilters(newFilterValues)
  }

  return (
    <div className="contractor">
      <div className="contractor__header">
        <div>
          <Header />
          <button onClick={() => navigate(-1)} className="contractor__back_btn">
            <DropLeftCircle /> <span>Back</span>
          </button>
        </div>
        <div className="contractor__button-and-logo">
          <Button
            icon={<UploadFileIcon />}
            onClick={() => setIsUploadDocumentModalOpen(true)}
            className="contractor__button"
          >
            Upload document
          </Button>
          <CentroHorizontalLogo className="contractor__logo" />
        </div>

        {isUploadDocumentModalOpen && (
          <UploadContractorDocumentModal
            isUploadModalOpen={isUploadDocumentModalOpen}
            setIsUploadModalOpen={setIsUploadDocumentModalOpen}
            documentsFilters={documentsFiltersData?.data?.data?.documentFilters}
            refetchContractorData={refetchContractorData}
          />
        )}
      </div>
      <div className="contractor__content-wrapper">
        <div className="contractor-left-wrapper">
          <div className="contractor-information glass">
            {contractorDataIsLoading ? (
              <Spinner centered />
            ) : (
              <>
                <div className="contractor-information-header">
                  <div className="contractor-information-header__left-container">
                    <h3 className="contractor-information-header__title">{contractor?.companyName}</h3>
                    <p className="contractor-information-header__clientid">Client ID # {contractor?.id}</p>
                    <p className="contractor-information-header__market">
                      <span className="contractor-information-header__market--bold">Market </span>
                      {contractor?.markets && contractor?.markets[0]?.name}
                    </p>
                  </div>
                  <div
                    onClick={() => navigate(`/contractor-add-edit/${id}`)}
                    className="contractor-information-header__right-container"
                  >
                    <div className="contractor-information-header__edit">
                      <RegistrationIcon />
                      <span className="contractor-information-header__edit-text">Edit</span>
                    </div>
                  </div>
                </div>
                <div className="contractor-information__content">
                  <div className="contractor-information__row">
                    <div className="contractor-information-field">
                      <div className="contractor-information-field__label">MC #</div>
                      <div className="contractor-information-field__text">{contractor?.motorCarrier}</div>
                    </div>
                    <div className="contractor-information-field">
                      <div className="contractor-information-field__label">USDOT#</div>
                      <div className="contractor-information-field__text">{contractor?.usdot}</div>
                    </div>
                  </div>
                  <div className="contractor-information__row">
                    <div className="contractor-information-field">
                      <div className="contractor-information-field__label">Address</div>
                      <div className="contractor-information-field__text">{contractor?.businessAddress}</div>
                    </div>
                    <div className="contractor-information-field">
                      <div className="contractor-information-field__label">Business Phone</div>
                      <div className="contractor-information-field__text">{contractor?.businessPhone}</div>
                    </div>
                  </div>
                  <div className="contractor-information__row">
                    <div className="contractor-information-field">
                      <div className="contractor-information-field__label">Contact Name</div>
                      <div className="contractor-information-field__text">
                        {contractor?.contactName} {contractor?.contactLastName}
                      </div>
                    </div>
                    <div className="contractor-information-field">
                      <div className="contractor-information-field__label">Contact Phone</div>
                      <div className="contractor-information-field__text">{contractor?.contactMobilePhone}</div>
                    </div>
                  </div>
                  <div className="contractor-information__row">
                    <div className="contractor-information-field">
                      <div className="contractor-information-field__label">Email Address</div>
                      <div className="contractor-information-field__text">{contractor?.contactEmail}</div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="contractor-chart glass">
            {contractorDataIsLoading ? (
              <Spinner centered />
            ) : (
              <>
                <div className="contractor-chart__left-container">
                  <h4 className="contractor-chart__title">Documentation Status</h4>{' '}
                  <DashboardPieLegend data={chartData} colors={chartColors} />
                </div>
                <div className="contractor-chart__right-container">
                  <DashboardPieChart
                    data={chartData}
                    colors={chartColors}
                    description="Total Documents"
                    count={contractor.documentationStatus?.total}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <div className="contractor-right-wrapper">
          <Form
            onSubmit={v => {}}
            initialValues={filters}
            render={({ handleSubmit, values, form: { change, reset } }) => (
              <form onSubmit={handleSubmit}>
                <ContractorDocuments
                  change={change}
                  reset={reset}
                  setFilters={setFilters}
                  isFiltersOpen={isFiltersOpen}
                  setIsFiltersOpen={setIsFiltersOpen}
                  contractorFilterData={contractorFilterData}
                  contractorDataIsLoading={contractorDataIsLoading}
                  refetchContractorData={refetchContractorData}
                  contractorData={contractor}
                  values={values}
                  filtersLength={Object.keys(values).length}
                  onSort={onSort}
                  filters={filters}
                />
              </form>
            )}
          />
        </div>
      </div>

      <div className="centered-copyright">
        <button className="contractor__delete-button" onClick={() => setIsDeleteModalOpen(!isDeleteModalOpen)}>
          Delete contractor
        </button>
        <p>© Centro Group LLC, 2023. All Rights Reserved.</p>{' '}
      </div>
      <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
        <div className="modal-content modal-content-delete-account">
          <p className="modal-content-delete-account__question">Are you sure you want to delete the contractor</p>
          <h2 className="modal-content-delete-account__name">{contractor?.companyName}</h2>
          <div className="modal-content__buttons">
            <Button className="modal-content__button" onClick={handleDeleteAccount}>
              Yes
            </Button>
            <Button className="modal-content__button" onClick={() => setIsDeleteModalOpen(false)}>
              No
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Contractor
