import React, { useState } from 'react'
import { Field, useFormState } from 'react-final-form'
import camelCase from 'lodash.camelcase'
import startCase from 'lodash.startcase'
import { metaToClassName } from 'utility/helpers'
import { validateEmail, validatePassword, validatePhoneNumber } from 'utility/validation'

import { EyeIcon, HideEyeIcon } from 'assets/icons'
import './Input.scss'

const Input = ({
  name,
  label,
  type,
  customValidation,
  placeholder,
  required,
  disabled,
  disableValidation,
  noValidate,
  withoutLabel,
  maxLenght,
  classNameModifier,
  ...props
}) => {
  if (type === undefined) type = 'text'
  if (placeholder === undefined) placeholder = ''
  if (required === undefined) required = false
  if (disabled === undefined) disabled = false
  if (label === undefined) label = startCase(name)
  if (name === undefined) name = camelCase(label)

  const formState = useFormState()
  const [showPassword, setShowPassword] = useState(false)
  const password = type === 'password'
  const email = type === 'email'
  const typeHandler = password ? (showPassword ? 'text' : 'password') : type

  const validateFunction = value => {
    if (noValidate) {
      return undefined
    }
    if (customValidation) {
      return customValidation(value)
    }
    if (!value && required) {
      return 'This is a Required Field'
    }
    if (value && password && validatePassword(value)) {
      return 'Password needs to have at least eight characters; at least one special character, lowercase letter, capital letter, and numeral.'
    }
    if (
      value &&
      formState?.values?.password &&
      (name === 'retypedPassword' || name === 'password_confirmation') &&
      value !== formState?.values?.password
    ) {
      return 'Passwords do not match'
    }
    if (value && email && validateEmail(value)) {
      return 'Invalid email address'
    }
    if (value && value.length > maxLenght) {
      return `The maximum length of this field is ${maxLenght}`
    }
    if (value && type === 'tel' && validatePhoneNumber(value)) {
      return 'Please use following format XXX-XXX-XXXX'
    }
    return undefined
  }

  return (
    <Field name={name} validate={validateFunction}>
      {({ input, meta }) => (
        <div className={`${metaToClassName('input', meta)} ${classNameModifier}`}>
          <label className="input__label" htmlFor={name}>
            {label}
            {required && <span className="input__required">{!withoutLabel ? '*' : ''}</span>}
          </label>
          {type === 'textarea' ? (
            <textarea {...input} {...props} placeholder={placeholder} disabled={disabled} className="input__field" />
          ) : (
            <div className="input__field-container">
              <input
                className="input__field"
                maxLength={maxLenght}
                {...input}
                {...props}
                type={typeHandler}
                placeholder={placeholder}
                required={required}
                disabled={disabled}
              />
              {password && (
                <button
                  className="input__icon-button"
                  onClick={e => {
                    e.preventDefault()
                    setShowPassword(s => !s)
                  }}
                >
                  {showPassword ? (
                    <EyeIcon className="input__icon input__icon--show" />
                  ) : (
                    <HideEyeIcon className="input__icon input__icon--hide" />
                  )}
                </button>
              )}
            </div>
          )}
          {meta.error && meta.touched && <span className="input__error">*{meta.error}</span>}
        </div>
      )}
    </Field>
  )
}

export default Input
