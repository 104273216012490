import React from 'react'
import { createPortal } from 'react-dom'
import { useProgressiveBackground } from 'utility/hooks'
import { XIcon } from 'assets/icons'

import './Modal.scss'
import clsx from 'clsx'

const Modal = ({ isOpen, onClose, children, className }) => {
  const background = useProgressiveBackground()
  return isOpen
    ? createPortal(
        <div className="modal-wrapper">
          <div className={clsx('modal', className)}>
            <div className="modal--background" {...background}>
              <XIcon className="modal__close-button" onClick={onClose} />
              {children}
            </div>
          </div>
        </div>,
        document.getElementById('root')
      )
    : null
}

export default Modal
