import React, { useEffect } from 'react'
import { FiltersIcon } from 'assets/icons'
import { Pagination } from 'components/Pagination'
import { FilterBadge } from 'components/FilterBadge'
import Filters from 'components/Filters/Filters'
import { Table } from 'components/Table'
import { PagesInfo } from 'pages/PagesInfo'
import { SearchInput } from 'components/SearchInput'
import { Spinner } from 'components/Spinner'
import { columns } from 'pages/Contractors/columns'

const ContractorsList = ({
  filters,
  setIsFiltersOpen,
  contractorsFilterData,
  contractorsData,
  contractorsDataLoading,
  setFilters,
  onSort,
  isFiltersOpen,
  change,
  filtersLength,
  reset,
  values,
}) => {
  useEffect(() => {
    setFilters(values)
  }, [values])

  return (
    <>
      <div className="contractors-filters">
        <FiltersIcon onClick={() => setIsFiltersOpen(true)} className="contractors-filters__icon" />
        <Filters
          documentsFiltersData={{
            ...contractorsFilterData?.data?.data?.contractorsFilters,
            clientId: contractorsFilterData?.data?.data?.contractorsFilters?.id,
          }}
          isOpen={isFiltersOpen}
          onClose={() => setIsFiltersOpen(false)}
          filtersArray={['company', 'markets', 'documentStatus', 'contact', 'createdAt']}
        />
        <span className="contractors-filters__text">Filter</span>
        {values &&
          Object.keys(values).map((el, i) => {
            return <FilterBadge onDelete={() => change(el, undefined)} key={el + i} fieldName={el} value={values[el]} />
          })}

        {filtersLength > 0 && (
          <button
            onClick={() => {
              setFilters({})
              reset()
            }}
            type="button"
            className="contractors-filters__delete-button"
          >
            Clear All filters
          </button>
        )}
      </div>
      <div className="glass contractors__content">
        <div className="contractors-table-header">
          <h4 className="contractors-table-header__title">Contractors List</h4>
          <SearchInput name="text" />
        </div>
        {contractorsDataLoading ? (
          <div className="contractors__spinner">
            <Spinner centered />
          </div>
        ) : (
          <>
            {' '}
            <Table
              data={contractorsData?.collection || []}
              columns={columns}
              sortable
              onSort={onSort}
              orderBy={filters?.orderBy}
            />
            <div className="contractors__pagination">
              <PagesInfo
                currentPage={contractorsData?.metadata?.currentPage}
                recordsPerPage={contractorsData?.metadata?.limitValue}
                totalRecords={contractorsData?.metadata?.totalCount}
                type="contractors"
              />
              <Pagination
                change={change}
                totalRecords={contractorsData?.metadata?.totalCount}
                totalPages={contractorsData?.metadata?.totalPages}
                currentPage={contractorsData?.metadata?.currentPage}
              />
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default ContractorsList
