import { ArrowLeftIcon, ArrowRightIcon } from 'assets/icons'
import clsx from 'clsx'
import React, { useEffect } from 'react'
import './Pagination.scss'

const Pagination = ({ totalPages, change, currentPage }) => {
  const renderPaginationArray = () => {
    const page = parseInt(currentPage)

    const totalPagesArray = [...Array(totalPages).keys()].map(i => i + 1)

    if (totalPages <= 8) {
      return totalPagesArray
    } else if (totalPages > 8 && page <= 3) {
      return [...totalPagesArray.slice(0, 3), '...', ...totalPagesArray.slice(totalPages - 3, totalPages)]
    } else if (totalPages > 8 && page > 3 && page > totalPages - 3) {
      return [...totalPagesArray.slice(0, 3), '..', ...totalPagesArray.slice(totalPages - 3, totalPages)]
    } else if (totalPages > 8 && page > 3 && page < totalPages - 2) {
      return [...totalPagesArray.slice(0, 3), '..', page, '..', ...totalPagesArray.slice(totalPages - 3, totalPages)]
    }
  }

  useEffect(() => {
    if (currentPage > totalPages) {
      change('page', 1)
    }
  }, [totalPages])

  const paginationArray = renderPaginationArray()

  return (
    <div className="pagination">
      <ArrowLeftIcon onClick={() => currentPage > 1 && change('page', currentPage - 1)} />
      {paginationArray?.map((el, i) => {
        return (
          <div
            key={i}
            onClick={() => el !== '...' && change('page', parseInt(el))}
            className={clsx('pagination__item', el === parseInt(currentPage) && 'pagination__item--selected')}
          >
            {el}
          </div>
        )
      })}
      <ArrowRightIcon onClick={() => currentPage < totalPages && change('page', currentPage + 1)} />
    </div>
  )
}

export default Pagination
